
<template>
<div class="bookmark_products_tab">
    <div v-if="bookmarks.length" class="bookmark_products_container">
    <Card
      v-for="product in bookmarks"
      :key="product.id"
      :thumbnail="product.thumbnail"
      :name="product.name"
      :price="product.price"
      :routeToProduct="() => routeToProduct(product.id)"
    />
    </div>
    <p v-else style="text-align: center; margin-top: 10%;">No Products to Show!</p>
    
</div>
</template>


<script>
import Card from '../Card'

export default {
  name: 'ProductsTab',
  components: { Card },
  data(){
    return {
      about: false
    }
  },
  computed: {
    user(){
      return this.$store.getters.user
    },
    company(){
      return this.$store.getters.company
    },
    bookmarks(){
      return this.$store.getters.bookmarks
    },
    savedStores(){
      return this.$store.getters.savedStores
    },
    collections(){
      return this.$store.getters.collections
    }
  },
  methods:{
    routeToProduct(id){
        this.$router.push('/seller/product/' + id)
    }
  }
}
</script>

<style>
.bookmark_products_tab{
    min-height: 600px;
}
.bookmark_products_container{
    display: flex;
    flex-wrap: wrap;
    padding: 23px;
    margin-left: 43px;
}
.bookmark_products_tab .item_card{
    margin-right: 34px;
}
</style>