<template>
  <div class="seller">
    <Navbar/>
    <div class="content">
       <div class="banner">
        <div class="top_profile_info">
          <img class="company_logo" :src="company.logo" alt="logo">
          <div class="info_text">
            <h1 style="color: #fff;">{{company.name}}</h1>
            <!-- <p style="color: #fff; font-wieght: 600;">{{user.company}}</p> -->
            <p style="color: #fff;font-style: italic; margin-bottom: 6px; font-wieght: light; font-size: 12px;">{{user.location.city}}, {{user.location.state}} {{user.location.zip}}</p>
          </div>
        </div>
        <div class="top_profile_stats">
          <div @click="() => this.$router.push('/seller/store')" class="top_profile_stat"><p style="font-weight:bold;">Products</p><p style="font-size: 17px;">{{company.products.length}}</p></div>
          <div @click="() => this.$router.push('/seller')" class="top_profile_stat"><p style="font-weight:bold;">LinQs</p><p style="font-size: 17px;">{{company.links.length}}</p></div>
          <div class="top_profile_stat"><p style="font-weight:bold;">Promoted</p><p style="font-size: 17px;">0</p></div>
        </div>
      </div>
      <div class="profile_top">
        <div class="profile_info"></div>
        <div class="button_container">
          <router-link class="top_button_link" to="/seller/addProduct"><div class="top_button">Add Product</div></router-link>
          <router-link class="top_button_link" to="/seller/store/edit"><div class="top_button">Edit Store</div></router-link>
        </div>
      </div>
      <el-tabs @tab-click="tabClicked" class="custom_tabs store_tabs" type="border-card">
        <el-tab-pane label="Active"><Active/></el-tab-pane>
        <el-tab-pane label="Inactive"><Inactive/></el-tab-pane>
        <el-tab-pane disabled label="Promoted"></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Navbar from '../../../components/Seller/Navbar'
import Active from '../../../components/Seller/Store/ActiveTab'
import Inactive from '../../../components/Seller/Store/InactiveTab'
export default {
  name: 'SellerStore',
  data(){
    return {
      about: false
    }
  },
  components:{Navbar, Active, Inactive},
  computed: {
    user(){
      return this.$store.getters.user
    },
    company(){
      return this.$store.getters.company
    }
  },
  methods:{
    tabClicked(tab){
      if(tab.label === 'Home'){
        this.about = false
      } else {
        this.about = true
      }
    }
  }
}
</script>


<style>
.seller{
  background: #f2f2f2;
  min-height: 100vh;
}
.store_tabs #tab-2{
  background: #bbb;
}
.content{
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.banner{
  width: 1425px;
  height: 189px;
  max-width: 85%;
  margin-top: 98px;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-size: cover;
}
.profile{
  width: 1425px;
  max-width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.company_logo{
  width: 230px;
  max-width: 230px;
  height: 230px;
  max-height: 230px;
  border-radius: 50%;
  margin-top: -95px;
  margin-left: 40px;
}
.store_info{
  display: flex;
}
.store_info h1{
  font-size: 31px;
  font-weight: 700;
}
.store_info h1, .store_info p {
  margin: 0;
}
.info_text{
  margin: 5px;
}
.button_container{
  display: flex;
  height: 100%;
  align-items: flex-start;
}
.top_button{
  width: 164px;
  height: 43px;
  background: #212B6A;
  margin: 4px;
  border-radius: 4px;
  margin-top: -43px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#fff;
  font-size: 14px;
}
.top_button:hover{
  cursor: pointer;
  background: #1b2459;
}
.top_button_link{
  color: #fff; 
  text-decoration: none;
  font-size: 14px;
}
.custom_tabs{
  width: 1425px;
  max-width: 85%;
  margin-top: 43px;
  border: none !important;
  box-shadow: none !important;
}
.el-tabs--border-card>.el-tabs__header{
  background: #f2f2f2 !important;
  border: none;
}
.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active, .el-tabs--border-card>.el-tabs__header .el-tabs__item{
  color: #000 !important;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding-top: 5px;
  border: none;
  border-radius: 4px 8px 0 0;
  width: 200px;
  height: 52px;
  margin: 0;
}
.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active:hover, .el-tabs--border-card>.el-tabs__header .el-tabs__item:hover{
  color: #212B6A !important;
}
.el-tabs--border-card>.el-tabs__header .el-tabs__item{
  border: .5px solid #ddd !important;
}
.top_profile_info{
  display: flex;
  justify-content: center;
  align-items: center;
}
.top_profile_stats{
  display: flex;
  width: 30%;
  justify-content: space-between;
}
.top_profile_stat{
  display: flex;
  flex-direction: column;
  justify-self: center;
  color: #fff;
  margin: 12px 23px;
  text-align: center;
}
.top_profile_stat:hover{
  cursor: pointer;
}
@media only screen and (max-width: 900px) {
  .profile_top{
    margin-top: 12px;
  }
}
</style>
