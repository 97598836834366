<template>
  <div class="seller">
    <Navbar/>
    <div class="content">
      <div :style="'background: url('+company.banner+');'" class="banner"></div>
      <div class="profile">
        <div class="profile_info">
          <img class="company_logo" :src="company.logo" alt="logo">
          <div class="info_text">
            <div v-if="user.id" class="company_feature_container">
              <h1>{{company.name}}</h1>
              <ion-icon @click="addSavedStore(company.id)" v-if="!user.savedStores.includes(company.id)" class="bookmark_icon" style="color: #222; margin-left: 13px; font-size: 27px;" name="bookmark"></ion-icon>
              <ion-icon @click="removeSavedStore(company.id)" v-else class="bookmark_icon" style="color: red; margin-left: 13px; font-size: 27px;" name="bookmark"></ion-icon>
            </div>
            <p style="  font-style: italic;">{{company.location.city}}, {{company.location.state}} {{company.location.zip}}</p>
            <p style="margin-top: 12px;"><span><img width="25" src="../../../assets/link_icon.png" alt="link"></span> {{company.links.length}}</p>            
          </div>
        </div>
      </div>
      <el-tabs @tab-click="tabClicked" class="custom_tabs" type="border-card">
        <el-tab-pane label="Home"><Home :products="products"/></el-tab-pane>
        <el-tab-pane label="About"><About :company="company"/></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Navbar from '../../../components/Seller/Navbar'
import Home from '../../../components/Seller/Bookmarks/Company/HomeTab'
import About from '../../../components/Seller/Bookmarks/Company/AboutTab'
export default {
  name: 'Seller',
  data(){
    return {
      company: {
          banner: '',
          logo: '',
          name: '',
          links: [],
          location: {
              city: '',
              state: '',
              zip: ''
          }
      },
      products: []
    }
  },
  components:{Navbar, Home, About},
  mounted(){
      this.$store.dispatch('grabStoreData', this.$route.params.id).then(data => {
          this.company = data.company
          this.products = data.products
      });
  },
  computed: {
    user(){
        return this.$store.getters.user
      },
  },
  methods:{
    tabClicked(tab){
      if(tab.label === 'Home'){
        this.about = false
      } else {
        this.about = true
      }
    },
    addSavedStore(id){
            this.user.savedStores.push(id)
            this.$store.dispatch('addSavedStore', id)
    },
    removeSavedStore(id){
        let index = this.user.savedStores.indexOf(id)
        this.user.savedStores.splice(index, 1)
        this.$store.dispatch('removeSavedStore', id)
        
    }
  }
}
</script>


<style>
.seller{
  background: #f2f2f2;
  min-height: 100vh;
}

.content{
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.banner{
  width: 1425px;
  height: 189px;
  max-width: 85%;
  margin-top: 98px;
  border-radius: 4px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}
.profile{
  width: 1425px;
  max-width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.company_logo{
  width: 230px;
  max-width: 230px;
  height: 230px;
  max-height: 230px;
  border-radius: 50%;
  margin-top: -95px;
  margin-left: 40px;
}
.profile_info{
  display: flex;
}
.profile_info h1{
  font-size: 31px;
  font-weight: 700;
}
.profile_info h1, .profile_info p {
  margin: 0;
}
.info_text{
  margin: 5px;
}
.button_container{
  display: flex;
  height: 100%;
  align-items: flex-start;
}
.top_button{
  width: 164px;
  height: 43px;
  background: #212B6A;
  margin: 4px;
  border-radius: 4px;
  margin-top: -43px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#fff;
  font-size: 14px;
}
.top_button:hover{
  cursor: pointer;
  background: #1b2459;
}
.top_button_link{
  color: #fff; 
  text-decoration: none;
  font-size: 14px;
}
.custom_tabs{
  width: 1425px;
  max-width: 85%;
  margin-top: 43px;
  border: none !important;
  box-shadow: none !important;
}
.el-tabs--border-card>.el-tabs__header{
  background: #f2f2f2 !important;
  border: none;
}
.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active, .el-tabs--border-card>.el-tabs__header .el-tabs__item{
  color: #000 !important;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding-top: 5px;
  border: none;
  border-radius: 4px 8px 0 0;
  width: 200px;
  height: 52px;
  margin: 0;
}
.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active:hover, .el-tabs--border-card>.el-tabs__header .el-tabs__item:hover{
  color: #212B6A !important;
}
.el-tabs--border-card>.el-tabs__header .el-tabs__item{
  border: .5px solid #ddd !important;
}
.company_feature_container{
  display: flex;
  align-items: center;
}
</style>
