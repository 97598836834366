<template>
    <div class="nav">
    <div class="logo">
      <router-link to="/seller"><img src="../../assets/logo.png" alt=""></router-link>
    </div>
    <div v-if="user.id" class="items">
      <router-link to="/seller/profile"><ion-icon class="menu_item" name="person-outline"></ion-icon></router-link>
      <router-link to="/seller"><ion-icon class="menu_item" name="home-outline"></ion-icon></router-link>
      <router-link to="/seller/store"><ion-icon class="menu_item" name="trending-up-outline"></ion-icon></router-link>
      <router-link to="/seller/bookmarks"><ion-icon class="menu_item" name="bookmark-outline"></ion-icon></router-link>
      <router-link to="/seller/messages"><ion-icon class="menu_item" name="mail-outline"></ion-icon></router-link>
      <el-dropdown>
        <span class="el-dropdown-link">
         <ion-icon class="menu_item" name="caret-down-outline"></ion-icon>
        </span>
        <el-dropdown-menu slot="dropdown">
          <a style="text-decoration: none;" href="#" @click="logoutUser()"><el-dropdown-item class="navbar_dropdown_item">Logout</el-dropdown-item></a>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  computed: {
    user(){
      return this.$store.getters.user
    },
  },
  methods: {
    async logoutUser() {
      await this.$store.dispatch('logoutUser')
      this.$router.push('/')
    }
  }
}
</script>

<style>

.nav{
  background: #212B6A;
  height: 70px;
  width: 100vw;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo img{
  max-width: 100px;
  padding-left: 22px;
}
.logo{
  height: 60px;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.menu_item{
  color:#fff;
  margin: 0 10px;
  font-size: 27px;
}
.navbar_dropdown_item:hover{
color: #212B6A  !important;
}

</style>