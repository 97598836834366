<template>
  <div class="seller">
    <Navbar/>
    <div class="content">
      <div class="banner">
        <div class="top_profile_info">
            <div v-if="!imageUrl" class="edit_profile_add_image">
              <input @change="handleNewProfileImage" class="edit_profile_file_input" type="file" id="img" name="img" accept="image/*">
              <ion-icon style="font-size: 48px;" class="menu_item" name="add-outline"></ion-icon>
            </div>
            <div v-else :style="'background: url('+this.imageUrl+'); background-repeat: no-repeat; background-size: cover; background-position:center;'" class="edit_profile_add_image_new">

            </div>
          <div class="info_text">
            <h1 style="color: #fff;">{{firstName}} {{lastName}}</h1>
            <p style="color: #fff; font-wieght: 600;">{{newCompany}}</p>
            <p style="color: #fff;font-style: italic; margin-bottom: 6px; font-wieght: light; font-size: 12px;">{{city}}, {{state}} {{zip}}</p>
          </div>
        </div>
        <div class="top_profile_stats">
          <div class="top_profile_stat"><p style="font-weight:bold;">Products</p><p style="font-size: 17px;">{{company.products.length}}</p></div>
          <div class="top_profile_stat"><p style="font-weight:bold;">Bookmarks</p><p style="font-size: 17px;">{{user.bookmarks.length}}</p></div>
          <div class="top_profile_stat"><p style="font-weight:bold;">Promoted</p><p style="font-size: 17px;">0</p></div>
        </div>
      </div>
      <div class="profile_top">
        <div class="profile_info"></div>
        <div class="button_container">
          <div @click="handleUpdateProfile()" class="edit_profile_top_button">Update Profile</div>
        </div>
      </div>
      <div class="edit_profile_form_container">
        <div class="edit_profile_input_container">
          <label style="color: #000; margin-bottom: -42px; font-size: 14px">First Name</label>
          <el-input v-model="firstName"></el-input>
        </div>
        <div class="edit_profile_input_container">
          <label style="color: #000; margin-bottom: -42px; font-size: 14px">Last Name</label>
          <el-input v-model="lastName"></el-input>
        </div>
        <div class="edit_profile_input_container">
          <label style="color: #000; margin-bottom: -42px; font-size: 14px">Company</label>
          <el-input v-model="newCompany"></el-input>
        </div>
        <div class="edit_profile_input_container">
          <label style="color: #000; margin-bottom: -42px; font-size: 14px">Phone Number</label>
          <el-input v-model="phone"></el-input>
        </div>
        <div class="edit_profile_input_container">
          <label style="color: #000; margin-bottom: -42px; font-size: 14px">City</label>
          <el-input v-model="city"></el-input>
        </div>
        <div class="edit_profile_input_container">
          <label style="color: #000; margin-bottom: -42px; font-size: 14px">State</label>
          <el-input v-model="state"></el-input>
        </div>
        <div class="edit_profile_input_container">
          <label style="color: #000; margin-bottom: -42px; font-size: 14px">Zip Code</label>
          <el-input v-model="zip"></el-input>
        </div>
        <div class="edit_profile_input_container">
          <label style="color: #000; margin-bottom: -42px; font-size: 14px">FEIN</label>
          <el-input v-model="fein"></el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '../../../components/Seller/Navbar'

export default {
  name: 'SellerEditProfile',
  data(){
    return {
      firstName: this.$store.getters.user.firstName,
      lastName: this.$store.getters.user.lastName,
      newCompany: this.$store.getters.user.company,
      phone: this.$store.getters.user.phone,
      city: this.$store.getters.user.location.city,
      state: this.$store.getters.user.location.state,
      zip: this.$store.getters.user.location.zip,
      fein: this.$store.getters.user.FEIN,  
      profileImage: this.$store.getters.user.profileImage,
      imageUrl: null
    }
  },
  components:{Navbar},
  computed: {
    user(){
      return this.$store.getters.user
    },
    company(){
      return this.$store.getters.company
    }
  },
  methods:{
    handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('Avatar picture must be JPG format!');
        }
        if (!isLt2M) {
          this.$message.error('Avatar picture size can not exceed 2MB!');
        }
        return isJPG && isLt2M;
      },
      handleUpdateProfile(){
        this.$store.dispatch('updateUserProfile', {
          firstName: this.firstName,
          lastName: this.lastName,
          newCompany: this.newCompany,
          phone: this.phone,
          city: this.city,
          state: this.state,
          zip: this.zip,
          fein: this.fein,
        })
      },
      async handleNewProfileImage(event){
        this.$store.dispatch('updateUserProfileImage', URL.createObjectURL(event.target.files[0]))
        this.imageUrl = URL.createObjectURL(event.target.files[0])
      }
  }
}
</script>


<style>
.seller{
  background: #f2f2f2;
  min-height: 100vh;
}
.content{
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.banner{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 1425px;
  height: 189px;
  max-width: 85%;
  margin-top: 98px;
  border-radius: 4px;
  background: rgb(2,0,36);
  background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(33,43,106,1) 70%);
}
.profile_top{
  width: 1425px;
  margin-top: 58px;
  max-width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top_profile_info{
  display: flex;
  justify-content: center;
  align-items: center;
}
.top_profile_stats{
  display: flex;
  width: 30%;
  justify-content: space-between;
}
.top_profile_stat{
  display: flex;
  flex-direction: column;
  justify-self: center;
  color: #fff;
  margin: 12px 23px;
  text-align: center;
}
.company_logo{
  width: 230px;
  max-width: 230px;
  height: 230px;
  max-height: 230px;
  border-radius: 50%;
  margin-bottom: -65px;
  margin-left: 40px;
  margin-right: 18px;
}
.edit_profile_add_image_new{
  width: 230px;
  max-width: 230px;
  height: 230px;
  max-height: 230px;
  border-radius: 50%;
  margin-bottom: -65px;
  margin-left: 40px;
  margin-right: 18px;
  display: flex;
  justify-content: center;
  align-items:center;
  position: relative;
}
.edit_profile_add_image{
  background: #000;
  width: 230px;
  max-width: 230px;
  height: 230px;
  max-height: 230px;
  border-radius: 50%;
  margin-bottom: -65px;
  margin-left: 40px;
  margin-right: 18px;
  display: flex;
  justify-content: center;
  align-items:center;
  position: relative;
}
.edit_profile_add_image:hover{
  background: #222;
  cursor: pointer;
}
.profile_info{
  display: flex;
}
.profile_info h1{
  font-size: 31px;
  font-weight: 700;
}
.banner h1, .banner p {
  margin: 0;
}
.info_text{
  margin: 5px;
}
.button_container{
  display: flex;
  height: 100%;
  align-items: flex-start;
}
.button_container:hover{
  cursor: pointer;
}
.edit_profile_top_button{
  width: 164px;
  height: 43px;
  background: #212B6A;
  margin: 4px;
  border-radius: 4px;
  margin-top: -43px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#fff;
  font-size: 14px;
    box-shadow: .5px 0px 6px .5px #ddd;
}
.edit_profile_top_button:hover{
  background: #1b2459;
}
.profile_top_button:hover{
  cursor: pointer;
}
.top_button_link{
  color: #000; 
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
}
.edit_profile_form_container{
  background: #fff;
  width: 1425px;
  height: 528px;
  max-width: 85%;
  margin-top: 24px;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}
.edit_profile_input_container{
  width: 45%;
}
.edit_profile_input_container input{
  margin-bottom: -50px !important;
  background: #f2f2f2 !important;
}
.edit_profile_file_input{
  opacity: 0; 
  width: 100%; 
  height: 100%; 
  position: absolute;
}
.edit_profile_file_input:hover{
  cursor: pointer;
}
</style>
