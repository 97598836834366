<template>
  <div class="loading">
    <img class="loading_logo" src="../assets/logo.png" alt="">
    <i class="el-icon-loading"></i>
  </div>
</template>



<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
export default {
  name: 'Loading',
  mounted() {
    setTimeout(() =>{
      if (!firebase.auth().currentUser) {
      this.$router.push('/login');
    } else {
      this.$store.dispatch('initialUserData', {id: firebase.auth().currentUser.uid, route: true})
    }
    }, 500)
  },
}
</script>



<style scoped>
h1{
  margin: 0;
  color: #fff;
}
i{
  color: #fff;
  margin-top: 33px;
  font-size: 33px;
}
.loading_logo{
  max-width: 300px;
}

.loading{
  background: #212B6A;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>