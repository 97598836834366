<template>
  <div class="messages">
    <Navbar/>
    <div class="messages_conatainer">
       <div class="convo_list">
           <div class="search_conatainer">
               <el-input
                    placeholder="Search"
                    prefix-icon="el-icon-search"
                    v-model="searchTerm"
                    style="margin-bottom: 24px"
                    clearable
                    >
                </el-input>
           </div>
           <div @click="setSelectedMessages(convo)" v-for="convo in conversations.filter(convo => {
                    return convo.users.buyer.name.toLowerCase().includes(this.searchTerm.toLowerCase())
                })" 
                :key="convo.id" :class="{'convo_item': true, 'selectedCovo': selectedMessage.id === convo.id ? true : false}">
                <!-- <div v-if="convo.users.seller.newMessage" class="unread_new_badge"></div> -->
               <img :src="convo.users.buyer.thumbnail" style="width: 40px; height: 40px; border-radius: 50%; margin-right: 12px;" />
               <div>
                   <p style="padding: 0; margin: 0; font-weight: bold; font-size: 18px;">{{convo.users.buyer.name}}</p>
                   <p style="padding: 0; margin: 0; font-weigth: 200; font-style: italic;">{{convo.messages[convo.messages.length - 1].text}}</p>
               </div>
           </div>
       </div>
       <div class="convo_messages">
           <div v-if="selectedMessage.id">
               <div class="message_banner">
               <img :src="selectedMessage.users.buyer.thumbnail" style="width: 40px; height: 40px; border-radius: 50%; margin: 0 8px;" />
               <p style="color: #fff; font-weight: bold; font-size: 18px;">{{selectedMessage.users.buyer.name}}</p>
           </div>
           <ul v-chat-scroll="{smooth: true, notSmoothOnInit: true}" class="messages_window" id="messaging_container">
               <li v-for="message in messages" :key="message._id" style="display: flex; align-items: center; padding: 3px 8px; flex-shrink: 0;" :class="{'user_message_item': message.user._id === user.id ? true : false}">
                   <img v-if="message.user._id !== user.id" :src="message.user.avatar" style="width: 40px; height: 40px; border-radius: 50%;" />
                   <p :class="{'message_bubble': true, 'message_item': true, 'user_message_item_bubble': message.user._id === user.id ? true : false}">{{message.text}}</p>
               </li>
           </ul>
           <div class="message_input">
                <form style="width: 100%" @submit="e => {
                        e.preventDefault();
                        sendMessage()
                    }">
                    <el-input
                    placeholder="Message"
                    v-model="newMessage"
                    >
                </el-input>
                </form>
            <el-button @click="sendMessage()" style="background: #2ecc71; color: #fff;">Send</el-button>
           </div>
           </div>
           <div style="width: 100%; height: 100%; display: flex; justify-content:center; align-items: center;" v-else>
               <p style="font-style: italic; font-weight: 100;">No Conversation Selected</p>
           </div>
       </div>
    </div>
  </div>
</template>

<script>
import Navbar from '../../../components/Seller/Navbar'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
export default {
  name: 'Messages',
  data(){
    return {
      convos: [],
      selectedMessage: {},
      searchTerm: '',
      newMessage: '',
      conversations: [],
      messages: []
    }
  },
  mounted(){
      //this.$store.dispatch('grabMessagingData')
      this.grabMessagingData()
      this.$nextTick(function(){
          console.log(document.getElementById("messaging_container"))
        //   var element = document.getElementById("messaging_container");
        //   element.scrollTop = element.scrollHeight ;
      })
  },
  components:{Navbar},
  computed: {
    user(){
      return this.$store.getters.user
    },
    company(){
      return this.$store.getters.company
    },
  },
  methods:{
      setSelectedMessages(convo){
          this.selectedMessage = convo
          this.grabSpecificMessageData(convo.id)
          if (convo.users.seller.newMessage) {
            firebase
                .firestore()
                .collection('users')
                .doc(this.user.id)
                .update({
                'notifications.messages': firebase.firestore.FieldValue.increment(-1),
                })
          }
            firebase
            .firestore()
            .collection('conversations')
            .doc(convo.id)
            .update({
                'users.seller.newMessage': false,
            })
      },
      grabMessagingData(){
        let conversations = []
        this.company.messages.forEach((id) => {
            firebase
            .firestore()
            .collection('conversations')
            .doc(id)
            .get()
            .then((data) => {
                conversations.push(data.data())
                this.conversations = conversations.sort((a, b) => {
                    return (
                    new Date(b.users.seller.lastUpdated) -
                    new Date(a.users.seller.lastUpdated)
                    )
                })
            })
        })
      },
      grabSpecificMessageData(id){
          firebase.firestore().collection('conversations').doc(id).onSnapshot(data  => {
              this.messages = data.data().messages
          })
      },
      sendMessage(){
        let messageId = this.selectedMessage.id
        let message =  {
            _id: Math.random()
                .toString(36)
                .substr(2, 10),
            text: this.newMessage,
            createdAt: new Date(),
            user: {
                _id: this.user.id,
                name: this.company.name,
                avatar: this.company.logo,
            },
            }

        if (this.selectedMessage.linked) {
        firebase
            .firestore()
            .collection('conversations')
            .doc(messageId)
            .update({
            messages: firebase.firestore.FieldValue.arrayUnion(message),
            'users.seller.lastUpdated': Date.now(),
            'users.buyer.lastUpdated': Date.now(),
            'users.buyer.newMessage': true,
            })
        if (!this.selectedMessage.users.buyer.newMessage) {
            firebase
            .firestore()
            .collection('users')
            .doc(this.selectedMessage.users.buyer.id)
            .update({
                'notifications.messages': firebase.firestore.FieldValue.increment(
                1
                ),
            })
        }
        } else {
        firebase
            .firestore()
            .collection('conversations')
            .doc(messageId)
            .update({
            linked: true,
            messages: firebase.firestore.FieldValue.arrayUnion(message),
            'users.seller.lastUpdated': Date.now(),
            'users.buyer.lastUpdated': Date.now(),
            'users.buyer.newMessage': true,
            })
        if (!this.selectedMessage.users.buyer.newMessage) {
            firebase
            .firestore()
            .collection('users')
            .doc(this.selectedMessage.users.buyer.id)
            .update({
                'notifications.messages': firebase.firestore.FieldValue.increment(
                1
                ),
            })
        }
        firebase
            .firestore()
            .collection('users')
            .doc(this.selectedMessage.users.buyer.id)
            .update({
            links: firebase.firestore.FieldValue.arrayUnion(
                this.selectedMessage.users.seller.id
            ),
            })
        firebase
            .firestore()
            .collection('companies')
            .doc(this.selectedMessage.users.seller.id)
            .update({
            links: firebase.firestore.FieldValue.arrayUnion(
                this.selectedMessage.users.buyer.id
            ),
            })
        }
        firebase
        .firestore()
        .collection('users')
        .doc(this.selectedMessage.users.buyer.id)
        .get()
        .then((data) => {
            let user = data.data()
            if (!user.messages.includes(messageId)) {
            data.ref.update({
                messages: firebase.firestore.FieldValue.arrayUnion(messageId),
            })
            }
        }) 
        this.$store.dispatch('sendNotification', {senderID: this.selectedMessage.users.buyer.id, message: this.newMessage})
        this.newMessage = ''
    }
    }
}
</script>


<style>
.messages{
    background: #f2f2f2;
    min-height: 100vh;
    padding-top: 120px;
}
.messages input {
  margin-bottom: 0 !important;
}
.messages_conatainer{
    width: 90%;
    display: flex;
    margin: auto;
    background: #fff;
    min-height: 80vh;
    border-radius: 5px;
    box-shadow: 1px 1px 12px 1px #ccc;
}
.convo_list{
    width: 30%;
    background: #fff;
    padding: 15px;
    overflow: scroll;
}
.convo_item{
    display: flex;
    align-items: center;
    border-radius: 4px;
    background: #f2f2f2;
    padding: 5px;
    margin-bottom: 8px;
    position: relative;
}
.convo_item:hover{
    cursor: pointer;
    background: #222;
    color: #fff;
}
.selectedCovo{
    background: #222;
    color: #fff;
}
.convo_messages{
    width: 70%;
    background: #f2f2f2;
    position: relative;
}
.message_banner{
    height: 60px;
    width: 100%;
    background: #222;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
}
.message_input{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
}
.messages_window{
    width: 100%;
    height: 100%;
    max-height: 70vh;
    background: #f2f2f2;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    margin-bottom: 65px;
    margin-top: 65px;
    padding: 0px;
}
.message_item{
    text-align: center;
    padding: 8px 18px;
    background: #fff;
    max-width: 320px;
    border-radius: 15px;
    margin: 0px 9px;
    font-weight: 100;
    color: #000;    
}
.user_message_item_bubble{
    background: #212B6A;
    color: #fff;
}
.user_message_item{
    align-self: flex-end;
}
.unread_new_badge{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #e74c3c;
    position: absolute;
    top: 0;
    right: 0;
    margin: -4px;
}
</style>
