<template>
  <div class="seller edit_store_container">
    <Navbar/>
    <div class="content">
      <div :style="'background: url('+newCompanyInfo.banner+'); background-repeat: no-repeat; background-size: cover; background-position: center; position: relative;'" class="edit_store_banner">
        <div class="edit_store_edit_fab"><input @change="handleNewBannerImage" class="edit_store_file_input" type="file"  name="img" accept="image/*"><ion-icon class="menu_item" name="create-outline"></ion-icon></div>
        <!-- <input @change="handleNewBannerImage()" class="edit_store_file_input" type="file" id="img" name="img" accept="image/*"> -->
        </div>
      <div class="profile edit_store_top_info">
        <div class="profile_info">
          <div class="edit_store_logo_container">
            <div class="edit_store_edit_fab"><input @change="handleNewLogoImage" class="edit_store_file_input" type="file"  name="img" accept="image/*"><ion-icon class="menu_item" name="create-outline"></ion-icon></div>
            <!-- <input @change="handleNewBannerImage()" class="edit_store_file_input" type="file" id="img" name="img" accept="image/*"> -->
            <img class="edit_store_company_logo" :src="newCompanyInfo.logo" alt="logo">
          </div>
          <div class="info_text">
            <h1>{{newCompanyInfo.name}}</h1>
            <p style="  font-style: italic;">{{newCompanyInfo.location.city}}, {{newCompanyInfo.location.state}} {{newCompanyInfo.location.zip}}</p>
            <p style="margin-top: 12px;"><span><img width="25" src="../../../assets/link_icon.png" alt="link"></span> {{newCompanyInfo.links.length}}</p>
          </div>
        </div>
        <div class="button_container">
          <div @click="updateStore()" class="top_button">Update Store</div>
        </div>
      </div>
      <div class="edit_store_form_container">
        <div class="edit_store_left_column">
          <label>Name</label>
          <el-input v-model="newCompanyInfo.name"></el-input>
          <label>About</label>
          <el-input v-model="newCompanyInfo.about" type="textarea"></el-input>
          <label>Policies</label>
          <el-input v-model="newCompanyInfo.policies" type="textarea"></el-input>
          <label>Address</label>
          <el-input v-model="newCompanyInfo.location.address"></el-input>
          <label>City</label>
          <el-input v-model="newCompanyInfo.location.city"></el-input>
          <label>State</label>
          <el-input v-model="newCompanyInfo.location.state"></el-input>
          <label>Zip Code</label>
          <el-input v-model="newCompanyInfo.location.zip"></el-input>
          <div style=" margin-left: 20px !important;" class="edit_product_category_container">
            <p>Categories</p>
            <el-tag @click="addCategory(category)" class="categoryTag" v-for="category in categories" :key="category.name" :color="newCompanyInfo.categories.includes(category.name) ? '#212B6A' : '#fff'" :hit="false" :style="newCompanyInfo.categories.includes(category.name) ? 'color: #fff !important; margin: 5px' : 'color: #000; margin: 5px'">{{category.name}}</el-tag>
         </div>
        </div>
        <div class="edit_store_right_column">
          <p style="text-align: center; margin-top: 0;" class="heading">Frequently Asked Questions</p>
          <label>Question</label>
          <el-input v-model="newQuestion"></el-input>
          <label>Answer</label>
          <el-input v-model="newAnswer"></el-input>
          <div @click="addFAQ()" class="add_faq_button">Add</div>
           <el-card v-for="(faq, i) in newCompanyInfo.faq" :key="faq.question" style="background: #fff; color:#000; border-radius: 4px; width: 90%; margin-top: 13px; margin-left: 8px;" shadow="always">
                <div slot="header" class="clearfix">
                    <span class="about_body_text" style="margin: 0; font-weight: 400;">Q: {{faq.question}}</span>
                    <el-button @click="removeFAQ(i)" style="float: right; padding: 3px 0; color: red;" type="text">X</el-button>
                </div>
                  <p style="font-weight: bold">A: {{faq.answer}}</p>
            </el-card>
          <p style="text-align: center; margin-top: 45px;" class="heading">Trade Shows & Events</p>
          <label>Select Event</label>
           <el-select v-model="newEvent" value-key="name" placeholder="Select Event">
            <el-option
              v-for="item in events"
              :key="item.name"
              :label="item.name"
              :value="item"
              >
            </el-option>
          </el-select>
          <label>Event Info</label>
          <el-input v-model="newEventInfo"></el-input>
          <div @click="addEvent()" class="add_faq_button">Add</div>
          <el-card v-for="(event, i) in newCompanyInfo.events" :key="event.event.name" style="background: #fff; color:#000; border-radius: 4px; width: 90%; margin-top: 13px; margin-left: 8px;" shadow="always">
            <div slot="header" class="clearfix">
                <span class="about_body_text" style="margin: 0; font-weight: 400;">{{event.event.name}}</span>
                <el-button @click="removeEvent(i)" style="float: right; padding: 3px 0; color: red;" type="text">X</el-button>
            </div>
            <p style="font-weight: bold">{{event.info}}</p>
            </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '../../../components/Seller/Navbar'

export default {
  name: 'SellerEditStore',
  data(){
    return {
      newCompanyInfo: {
        location: {},
        links: 0,
        events: [],
        faq: [],
        categories: []
      },
      newQuestion: '',
      newAnswer: '',
      newEventInfo: '',
      newEvent: {},
    }
  },
  components:{Navbar},
  computed: {
    user(){
      return this.$store.getters.user
    },
    company(){
      return this.$store.getters.company
    },
    categories(){
        return this.$store.getters.categories
      },
      events(){
        return this.$store.getters.events
      }
  },
  mounted(){
    this.newCompanyInfo = this.company
    this.$store.dispatch('grabCategories')
    this.$store.dispatch('grabEvents')
  },
  methods:{
      updateStore(){
        this.$store.dispatch('updateStore', this.newCompanyInfo)
      },
      async handleNewLogoImage(event){
       this.$store.dispatch('updateStoreLogo', event.target.files[0])
       this.newCompanyInfo.logo = URL.createObjectURL(event.target.files[0])
      },
      async handleNewBannerImage(event){
       this.$store.dispatch('updateStoreBanner', event.target.files[0])
       this.newCompanyInfo.banner = URL.createObjectURL(event.target.files[0])
      },
      addFAQ(){
        this.newCompanyInfo.faq.push({
          question: this.newQuestion,
          answer: this.newAnswer,
        })
        this.newQuestion = ''
        this.newAnswer = ''
      },
      addEvent(){
        this.newCompanyInfo.events.push({
          event: this.newEvent,
          info: this.newEventInfo,
        })
        this.newEvent = {}
        this.newEventInfo = ''
      },
      removeFAQ(i){
        this.newCompanyInfo.faq.splice(i, 1)
      },
      removeEvent(i){
        this.newCompanyInfo.events.splice(i, 1)
      },
      addCategory(category){
        if(this.newCompanyInfo.categories.includes(category.name)){
            let cat = this.newCompanyInfo.categories.indexOf(category.name)
            this.newCompanyInfo.categories.splice(cat, 1)
        } else {
            this.newCompanyInfo.categories.push(category.name)
        }
      },
      selectEvent(e){
        this.newEvent = e
      }
  }
}
</script>


<style>
.edit_store_container{
  padding-bottom: 64px;
}
.edit_store_container .heading{
  font-size: 24px;
}
.edit_store_container .el-select{
  display: block !important;
}
.edit_store_file_input{
  opacity: 0; 
  width: 100%; 
  height: 100%; 
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;

}
.edit_store_file_input:hover{
  cursor: pointer;
 
}

.edit_store_top_info{
  z-index: 23;
}
.edit_store_banner{
  width: 1425px;
  height: 189px;
  max-width: 85%;
  margin-top: 98px;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-size: cover;
}
.edit_store_logo_container{
  position: relative;
   width: 230px;
  max-width: 230px;
  height: 230px;
  max-height: 230px;
  border-radius: 50%;
  margin-top: -95px;
  margin-left: 40px;
}
.edit_store_company_logo{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.edit_store_edit_fab{
    background: #212B6A;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.edit_store_edit_fab:hover{
    cursor: pointer;
    background: #222;
}
.edit_store_form_container{
  background: #fff;
  width: 1425px;
  max-width: 85%;
  margin-top: 43px;
  min-height: 400px;
  padding: 32px 0 ;
  display: flex;
}
.edit_store_left_column, .edit_store_right_column{
  width: 50%;
}
.edit_store_left_column input {
    margin: 0;
    height: 40px !important;
    max-width: 90%;
    margin-left: 20px !important;
    background: #f2f2f2;
    margin-bottom: 23px;
}
.edit_store_left_column textarea{
    margin: 0 !important;
    max-width: 90%;
    margin-left: 20px !important;
    margin-bottom: 23px !important;
    background: #f2f2f2;
    min-height: 100px !important;
}
.edit_store_left_column label{
    color: #000;
    font-weight: 600;
    font-size: 14px;
    margin-left: 20px !important;
}
.edit_store_right_column input {
    margin: 0;
    height: 40px !important;
    max-width: 90%;
    margin-left: 20px !important;
    background: #f2f2f2;
    margin-bottom: 13px;
}
.edit_store_right_column textarea{
    margin: 0 !important;
    max-width: 90%;
    margin-left: 20px !important;
    margin-bottom: 23px !important;
    background: #f2f2f2;
    min-height: 100px !important;
}
.edit_store_right_column label{
    color: #000;
    font-weight: 600;
    font-size: 14px;
    margin-left: 20px !important;
}
.add_faq_button{
  text-align: center;
  background: #212B6A;
  color: #fff;
  border-radius: 4px;
  width: 40%;
  margin: auto;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
