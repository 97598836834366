<template>
  <div class="bookmarks">
    <Navbar/>
    <div class="content">
        <div class="bookmark_top_cont">
          <div class="emtpy_div"></div>
          <h1 style="margin-top: 44px; display: block;">Bookmarks</h1>
           <el-button @click="dialogVisible = true" class="search_button" icon="el-icon-search" circle></el-button>
        </div>
      <el-tabs class="custom_tabs" type="border-card">
        <el-tab-pane label="Products"><Products/></el-tab-pane>
        <el-tab-pane label="Q's"><Q/></el-tab-pane>
        <el-tab-pane label="Sellers"><Sellers/></el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog
      title="Search Products"
      :visible.sync="dialogVisible"
      width="70%"
      >
      <el-input
        placeholder="Search Items"
        prefix-icon="el-icon-search"
        v-model="searchTerm"
        >
      </el-input>
        <div class="results_container">
          <div @click="routeToItem(product)" v-for="product in allProducts" :key="product.id" class="search_seller_card">
            <div style="display: flex">
            <img :src="product.thumbnail || product.logo"/>
            <p>{{product.name}}</p>
        </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Close</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Navbar from '../../../components/Seller/Navbar'
import Products from '../../../components/Seller/Bookmarks/ProductsTab'
import Q from '../../../components/Seller/Bookmarks/QTab'
import Sellers from '../../../components/Seller/Bookmarks/SellersTab'
export default {
  name: 'Seller',
  data(){
    return {
      dialogVisible: false ,
      searchTerm: ''
    }
  },
  components:{Navbar, Products, Q, Sellers},
  mounted(){
      this.$store.dispatch('grabBookmarkData')
      this.$store.dispatch('grabAllProducts')
      this.$store.dispatch('grabAllCompanies')
  },
  computed: {
    user(){
      return this.$store.getters.user
    },
    company(){
      return this.$store.getters.company
    },
    bookmarks(){
      return this.$store.getters.bookmarks
    },
    savedStores(){
      return this.$store.getters.savedStores
    },
    collections(){
      return this.$store.getters.collections
    },
    allProducts(){
      let allItems = this.$store.getters.allProducts.concat(this.$store.getters.allCompanies)
      return allItems.filter(product => {
          return product.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      })
    }
  },
  methods:{
    routeToItem(item){
      if(item.logo){
        this.$router.push('/seller/company/' + item.id)
      } else {
        this.$router.push('/seller/product/' + item.id)
      }
    }
  }
}
</script>


<style>
.bookmarks{
  background: #f2f2f2;
  min-height: 100vh;
  padding-bottom: 55px;
}
.bookmarks input {
  margin-bottom: 0 !important;
}

.results_container{
  height: 550px;
  overflow: scroll;
}
.bookmarks .custom_tabs{
  width: 95%;
  margin-top: 43px;
  border: none !important;
  box-shadow: none !important;
}
.bookmarks .el-tabs--border-card>.el-tabs__header{
  background: #f2f2f2 !important;
  border: none;
}
.bookmarks .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active, .el-tabs--border-card>.el-tabs__header .el-tabs__item{
  color: #000 !important;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding-top: 5px;
  border: none;
  border-radius: 4px 8px 0 0;
  width: 200px;
  height: 52px;
  margin: 0;
}
.bookmarks .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active:hover, .el-tabs--border-card>.el-tabs__header .el-tabs__item:hover{
  color: #212B6A !important;
}
.bookmarks .el-tabs--border-card>.el-tabs__header .el-tabs__item{
  border: .5px solid #ddd !important;
}
.bookmark_top_cont{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}
.search_button{
  color: #000 !important;
  font-size: 23px !important;
  display: block !important;
  margin-bottom: -64px !important;
}
.search_seller_card{
    padding-left: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-weight: bold;
    font-size: 18px;
    max-width: 80%;
    margin: auto;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.search_seller_card:hover{
    cursor: pointer;
    background: #222;
    color: #fff;
}

.search_seller_card img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 12px;
    margin-top: 5px;
}
</style>
