<template>
    <div class="notificationsTabContent">
        <div class="notificationsList">
            <div @click="setSelectedNotification(notification)" v-for="notification in notifications" :key="notification.message" :class="{'notification_list_item': true, 'active_notification': notification === selectedNotification ? true : false}"><img class="notification_icon_image" src="../../../assets/linq_icon.png"/>
            <!-- <div v-if="!notification.read" class="notification_new_badge"></div> -->
            {{notification.title}}
            </div>
        </div>
        <div class="notificationsInfo">
          <p class="notification_date_info" v-if="selectedNotification.title">{{this.moment(selectedNotification.date.toDate()).format('L')}}</p>
          <p style="font-size: 30px; font-weight: 700;">{{selectedNotification.title}}</p>
          <p>{{selectedNotification.message}}</p>
          
        </div>
    </div>
</template>


<script>
import moment from 'moment';


export default {
    name: 'NotificationsTab',
    data(){
      return {
        selectedNotification: {}
      }
    },
    computed: {
    user(){
      return this.$store.getters.user
    },
    company(){
      return this.$store.getters.company
    },
     notifications(){
      return this.$store.getters.notifications
    }
  },
  methods:{
    moment: function (date) {
    return moment(date);
  },
  setSelectedNotification(notification){
    this.selectedNotification = notification;
  }
  }
}
</script>


<style>
.notificationsTabContent{
    width: 100%;
    display: flex;
}
.notificationsList{
  width: 25%;
  background: #f2f2f2;
  padding: 9px;
  padding-top: 0;
  border-radius: 4px;
  height: 528px;
  max-height: 528px;
  overflow: scroll;
}
.notification_list_item{
  height: 40px;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #000;
  font-weight: 500;
  margin: 10px 0;
  position: relative;
}
.notification_list_item:hover{
  background: #222;
  color: #fff;
  cursor: pointer;
}
.notificationsInfo{
  width: 75%;
  padding: 0 23px;
  text-align: center;
  height: 500px;
  max-height: 500px;
  overflow: scroll;
}
.notification_icon_image{
  max-width: 28px; 
  border-radius: 4px; 
  margin-right: 8px; 
  margin-left: 23px;
}
.notification_date_info{
  position: absolute;
  top: 0;
  right: 0;
  margin: 12px;
  font-weight: bold;
  font-size: 12px;
}
.active_notification{
  color: #fff;
  background: #222;
}
.notification_new_badge{
  background: #e74c3c;
  padding: 7px;
  color: #fff;
  font-size: 10px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 0;
  margin: -5px;
}
</style>