<template>
  <div class="seller">
    <Navbar/>
    <div class="content">
      <div :style="'background: url('+company.banner+'); background-repeat: no-repeat; background-size: cover; background-position: center'" class="banner"></div>
      <div class="profile">
        <div class="profile_info">
          <img class="company_logo" :src="about ? user.profileImage : company.logo" alt="logo">
          <div class="info_text">
            <h1>{{company.name}}</h1>
            <p style="  font-style: italic;">{{company.location.city}}, {{company.location.state}} {{company.location.zip}}</p>
            <p style="margin-top: 12px;"><span><img width="25" src="../../../assets/link_icon.png" alt="link"></span> {{company.links.length}}</p>
          </div>
        </div>
        <div class="button_container">
          <router-link class="top_button_link" to="/seller/store/edit"><div class="top_button">Edit Store</div></router-link>
          <router-link class="top_button_link" to="/seller/store"><div class="top_button">Manage Products</div></router-link>
        </div>
      </div>
      <el-tabs @tab-click="tabClicked" class="custom_tabs" type="border-card">
        <el-tab-pane label="Home"><Home/></el-tab-pane>
        <el-tab-pane label="About"><About/></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Navbar from '../../../components/Seller/Navbar'
import Home from '../../../components/Seller/Home/HomeTab'
import About from '../../../components/Seller/Home/AboutTab'
export default {
  name: 'Seller',
  data(){
    return {
      about: false
    }
  },
  components:{Navbar, Home, About},
  computed: {
    user(){
      return this.$store.getters.user
    },
    company(){
      return this.$store.getters.company
    }
  },
  methods:{
    tabClicked(tab){
      if(tab.label === 'Home'){
        this.about = false
      } else {
        this.about = true
      }
    }
  }
}
</script>


<style>
.seller{
  background: #f2f2f2;
  min-height: 100vh;
  padding-bottom: 54px;
}
.content{
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.banner{
  width: 1425px;
  height: 189px;
  max-width: 85%;
  margin-top: 98px;
  border-radius: 4px;
}
.profile{
  width: 1425px;
  max-width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.company_logo{
  width: 230px;
  max-width: 230px;
  height: 230px;
  max-height: 230px;
  border-radius: 50%;
  margin-top: -95px;
  margin-left: 40px;
}
.profile_info{
  display: flex;
}
.profile_info h1{
  font-size: 31px;
  font-weight: 700;
}
.profile_info h1, .profile_info p {
  margin: 0;
}
.info_text{
  margin: 5px;
}
.button_container{
  display: flex;
  height: 100%;
  align-items: flex-start;
}
.top_button{
  width: 164px;
  height: 43px;
  background: #212B6A;
  margin: 4px;
  border-radius: 4px;
  margin-top: -43px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#fff;
  font-size: 14px;
}
.top_button:hover{
  cursor: pointer;
  background: #1b2459;
}
.top_button_link{
  color: #fff; 
  text-decoration: none;
  font-size: 14px;
}
.custom_tabs{
  width: 1425px;
  max-width: 85%;
  margin-top: 43px;
  border: none !important;
  box-shadow: none !important;
}
.el-tabs--border-card>.el-tabs__header{
  background: #f2f2f2 !important;
  border: none;
}
.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active, .el-tabs--border-card>.el-tabs__header .el-tabs__item{
  color: #000 !important;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding-top: 5px;
  border: none;
  border-radius: 4px 8px 0 0;
  width: 200px;
  height: 52px;
  margin: 0;
}
.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active:hover, .el-tabs--border-card>.el-tabs__header .el-tabs__item:hover{
  color: #212B6A !important;
}
.el-tabs--border-card>.el-tabs__header .el-tabs__item{
  border: .5px solid #ddd !important;
}
@media only screen and (max-width: 900px) {
  .company_logo{
    width: 150px !important;
    height: 150px !important;
    margin-top: -65px !important;
  }
  .top_button{
    width: 140px !important;
    margin: 0 !important;
    margin-bottom: 8px !important;
  }
  .button_container{
    flex-direction: column;
  }
}
</style>
