import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import VueRouter from 'vue-router'
import store from '../store'
//import Home from '../views/Home.vue'
import Loading from '../views/Loading.vue'
import PageNotFound from '../views/PageNotFound'
import Login from '../views/Auth/Login'
import SellerHome from '../views/Seller/Home/Home'
import SellerProfile from '../views/Seller/Profile/Profile'
import SellerEditProfile from '../views/Seller/Profile/EditProfile'
import SellerStore from '../views/Seller/Store/Store'
import SellerEditStore from '../views/Seller/Store/EditStore'
import SellerProduct from '../views/Seller/Store/Product'
import SellerAddProduct from '../views/Seller/Store/SellerAddProduct'
import SellerEditProduct from '../views/Seller/Store/EditProduct'
import SellerBookmarks from '../views/Seller/Bookmarks/Bookmarks'
import SellerCompanyPage from '../views/Seller/Bookmarks/CompanyPage'
import SellerMessages from '../views/Seller/Messaging/Messages'
import BuyerHome from '../views/Buyer/Home'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Loading,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/buyer',
    name: 'Buyer',
    component: BuyerHome,
    meta: {
      requiresAuth: true,
      buyerAuth: true,
    },
  },
  {
    path: '/seller',
    name: 'Seller',
    component: SellerHome,
    meta: {
      requiresAuth: true,
      sellerAuth: true,
    },
  },
  {
    path: '/seller/profile',
    name: 'Seller Profile',
    component: SellerProfile,
    meta: {
      requiresAuth: true,
      sellerAuth: true,
    },
  },
  {
    path: '/seller/profile/edit',
    name: 'Seller Edit Profile',
    component: SellerEditProfile,
    meta: {
      requiresAuth: true,
      sellerAuth: true,
    },
  },
  {
    path: '/seller/store',
    name: 'Seller Store',
    component: SellerStore,
    meta: {
      requiresAuth: true,
      sellerAuth: true,
    },
  },
  {
    path: '/seller/store/edit',
    name: 'Seller Edit Store',
    component: SellerEditStore,
    meta: {
      requiresAuth: true,
      sellerAuth: true,
    },
  },
  {
    path: '/seller/product/:id',
    name: 'SellerProduct',
    component: SellerProduct,
    meta: {
      requiresAuth: true,
      sellerAuth: true,
    },
  },
  {
    path: '/seller/addProduct',
    name: 'SellerAddProduct',
    component: SellerAddProduct,
    meta: {
      requiresAuth: true,
      sellerAuth: true,
    },
  },
  {
    path: '/seller/product/edit/:id',
    name: 'SellerEditProduct',
    component: SellerEditProduct,
    meta: {
      requiresAuth: true,
      sellerAuth: true,
    },
  },
  {
    path: '/seller/company/:id',
    name: 'SellerCompanyPage',
    component: SellerCompanyPage,
    meta: {
      requiresAuth: true,
      sellerAuth: true,
    },
  },
  {
    path: '/seller/bookmarks',
    name: 'SellerBookmarks',
    component: SellerBookmarks,
    meta: {
      requiresAuth: true,
      sellerAuth: true,
    },
  },
  {
    path: '/seller/messages',
    name: 'SellerMessages',
    component: SellerMessages,
    meta: {
      requiresAuth: true,
      sellerAuth: true,
    },
  },
  { path: '*', component: PageNotFound },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!firebase.auth().currentUser) {
      next('/')
    } else {
      if (to.meta.sellerAuth) {
        if (store.getters.userType === 'seller') {
          next()
        } else {
          next('/buyer')
        }
      } else if (to.meta.buyerAuth) {
        if (store.getters.userType === 'buyer') {
          next()
        } else {
          next('/seller')
        }
      }
    }
  } else {
    next()
  }
})

export default router
