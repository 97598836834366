<template>
<div class="aboutTabContent">
    <p class="heading">About</p>
    <p style="font-style: italic; font-weight: 100; width: 80%;">LinQ is making it easier than ever before to connect with the wholesale industry. Discover new products and businesses from the convenience of your smartphone and manage new connections from one central hub.</p>
    
</div>
</template>




<script>

export default {
    name: 'AboutTab',
    
}
</script>


<style>
.aboutTabContent{
    height: 530px;
}
</style>