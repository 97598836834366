let config = {
  apiKey: 'AIzaSyDrbwq-AdK1r9at7LljPTZIGuAvQ4DcdyQ',
  authDomain: 'linq-developement.firebaseapp.com',
  databaseURL: 'https://linq-developement.firebaseio.com',
  projectId: 'linq-developement',
  storageBucket: 'linq-developement.appspot.com',
  messagingSenderId: '768527664343',
  appId: '1:768527664343:web:c12dd52fb5d766ce289c92',
  measurementId: 'G-1G4S01DVL0',
}

export default config
