<template>
<div class="tab_content">
    <p style="margin-top: 0; margin-left: 43px;" class="heading">Active Products</p>
    <div v-if="activeProducts.length" class="store_active_products_container">
        <Card
        v-for="product in activeProducts"
        :key="product.id"
        :thumbnail="product.thumbnail"
        :name="product.name"
        :price="product.price"
        :routeToProduct="() => routeToProduct(product.id)"
      />
    </div>
    <p style="font-size:23px; font-weight: 400; text-align: center; margin-top: 55px;" v-else>No Products to Show!</p>
</div>
    
</template>


<script>
import Card from '../../../components/Seller/Card'
export default {
    name: 'HomeTab',
    components: { Card },
    computed: {
    user(){
      return this.$store.getters.user
    },
    company(){
      return this.$store.getters.company
    },
    products(){
      return this.$store.getters.products
    },
    activeProducts(){
      return this.$store.getters.activeProducts
    },
    featuredProducts(){
      return this.$store.getters.featuredProducts
    }
  },
  methods:{
     routeToProduct(id){
            this.$router.push('/seller/product/' + id)
        }
  }
}
</script>


<style>
.heading{
    font-size: 28px;
    font-weight: bold;
    
}
.item_card{
    width: 225px;
    margin-right: 23px;
    margin-bottom: 23px;
}
  .time {
    font-size: 13px;
    color: #ddd;
  }
  
  .bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {
    width: 100%;
    display: block;
    height: 260px;
  }

  .clearfix:before,
  .clearfix:after {
      display: table;
      content: "";
  }
  
  .clearfix:after {
      clear: both
  }
  .store_active_products_container{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-left: 43px;
  }
</style>