<template>
<div class="helpTabContent">
    <p class="heading">Contact Us</p>
    <p style="font-size: 15px;font-style: italic; font-weight: 600; width: 80%;">LinQ B2B LLC</p>
    <p style="font-size: 15px;font-style: italic; font-weight: 100; width: 80%;">PO Box 312 Concan, Tx 78838</p>
    <a href="mailto:info@LinQB2B.com" class="help_info_text">info@LinQB2B.com</a>
    <a href="tel:1-855-546-7222" class="help_info_text">1-(855)-546-7222</a>
    <a href="tel:1-855-546-7222" class="help_info_text">1-(855)-LINQB2B</a>
    <el-divider></el-divider>
    <div class="help_button_container">
        <a href="https://linqb2b.com/how-to/" target="_blank"><div class="help_top_button">View Online Resources</div></a>
        <div @click="handleRequestFeature()" class="help_top_button">Request a Feature</div>
    </div>
</div>
</template>




<script>

export default {
    name: 'HelpTab',
    computed: {
    user(){
      return this.$store.getters.user
    }
  },
    methods: {
        handleRequestFeature: function(){
            this.$prompt('Request', 'We appreciate all feedback!', {
          confirmButtonText: 'Submit',
          cancelButtonText: 'Cancel',
        }).then(({ value }) => {
          this.$store.dispatch('sendRequestFeature', value);        
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Action canceled'
          });       
        });
        },
    }
    
}
</script>


<style>
.helpTabContent{
    height: 530px;
}
.help_top_button{
  width: 164px;
  height: 43px;
  background: #212B6A;
  margin-right: 4px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#fff;
  font-size: 12px;
}
.help_top_button:hover{
  cursor: pointer;
  background: #1b2459;
}
.help_button_container{
  display: flex;
  height: 100%;
  align-items: flex-start;
}
.help_info_text{
    font-size: 15px;
    font-style: italic; 
    font-weight: 100; width: 80%; 
    text-decoration: none; 
    color: #000;
    display: block;
    margin-top: 15px;
}
.help_info_text:hover{
    color: #3498db;
}
</style>