<template>
    <div class="about_container">
        <div class="about_info">
            <p class="heading">About</p>
            <p class="about_body_text">{{company.about}}</p>
            <p class="heading">Policies</p>
            <p class="about_body_text">{{company.policies}}</p>
        </div>
        <div class="about_faq">
            <p class="heading">Frequently Asked Questions</p>
            <el-collapse accordion>
                <el-collapse-item v-for="question in company.faq" :key="question.question" :title="question.question">
                    <div style="font-weight: bold">{{question.answer}}</div>
                </el-collapse-item>
            </el-collapse>
            <p class="heading">Trade Shows & Events</p>
            <el-card v-for="event in company.events" :key="event.name" style="background: #222; color:#fff;" shadow="always">
           <p class="about_body_text" style="margin: 0; font-weight: 400;">{{event.event.name}}</p>
           <p class="about_body_text" style="margin: 0; font-style: italic;">{{event.info}}</p>
            </el-card>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutTab',
    data(){
        return {
           
        }
    },
    computed: {
    user(){
      return this.$store.getters.user
    },
    company(){
      return this.$store.getters.company
    },
  },
}
</script>

<style>
.about_container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
}
.about_info, .about_faq{
    width: 45%;
}
.heading{
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 12px;
}
.about_body_text{
    font-weight: 100;
}
.about_faq .el-card__body {
    padding: 10px;
}


</style>