<template>
  <div class="login">
    <div class="formContainer">
    <img class="login_logo" src="../../assets/logo.png" alt="">
    <div class="form">
      <label style="color: #fff; margin-bottom: -42px;" for="email">Email</label>
      <el-input id="email" placeholder="Email" v-model="email"></el-input>
      <label style="color: #fff; margin-bottom: -42px;" for="password">Password</label>
      <el-input id="password" placeholder="Password" show-password v-model="password"></el-input>
      <div @click="handleLogin()" class="submit"> <p>Login</p> </div>
    </div>
    <p class="forgot">Forgot Password?</p>
    </div>
    <p style="color: #E52323">{{errors}}</p>
  </div>
</template>


<script>
import firebase from 'firebase/app'
import 'firebase/auth'
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
    }
  },
  computed: {
        loading() {
            return this.$store.getters.loading;
        },
        errors() {
            return this.$store.getters.errors;
        }
    },
  mounted(){
    firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      this.$store.dispatch('initialUserData', {id: firebase.auth().currentUser.uid, route: true})
    } 
  })},
  methods:{
    handleLogin() {
      this.$store.dispatch('loginUser', {
          email: this.email,
          password: this.password
      });
    }
  }
}
</script>


<style>
.login h1{
  margin: 0px;
}
.login{
  width: 100vw;
  height: 100vh;
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.login_logo{
  max-width: 100%;
  width: 240px;
  margin-top: 23px;
}
.formContainer{
  background: #212B6A;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 40%;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 1px 0px 12px 1px #ddd;
  max-width: 600px;
}
.form{
  margin-top: 80px;
  width: 408px;
  max-width: 80%;
}
.el-input__inner{
  height: 60px !important;
}
.el-input__icon{
  height: 50%;
}
input{
  margin-bottom: 58px;
}
.submit{
  background: #212B6A;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #E52323;
  width: 408px;
  height: 78px;
  border-radius: 4px;
  margin: auto;
  max-width: 80%;
}
.submit:hover{
  cursor: pointer;
}
.submit p {
  color: #fff;
  font-weight: 700;
  text-align: center;
  font-size: 39px;
}
.forgot{
  color: #fff;
  margin-top: 23px;
}
@media only screen and (max-width: 900px) {
  .formContainer{
    width: 90%;
  }
  .form{
    width: 100%;
  }
}
</style>