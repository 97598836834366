import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/analytics'
import firebaseConfig from '../firebaseConfig'
import VueCarousel from 'vue-carousel'
import VueChatScroll from 'vue-chat-scroll'

firebase.initializeApp(firebaseConfig)
firebase.analytics()

Vue.use(VueChatScroll)
Vue.use(ElementUI)
Vue.use(VueCarousel)

Vue.config.productionTip = false
Vue.config.ignoredElements = [/^ion-/]

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
