<template>
  <div id="app">
    <router-view class="desktop"/>
    <PhoneView class="mobile"/>
    <Footer/>
  </div>
</template>

<script>
import PhoneView from './views/PhoneView';
import Footer from './components/Footer'
export default {
  name: 'App',
  components: {PhoneView,Footer}
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
  *{
    font-family: 'Nunito Sans', sans-serif !important;
  }
  #app, body, html{
    margin: 0;
    padding: 0;
  }
  .mobile{
    display: none !important;
  }  
  @media only screen and (max-width: 600px) {
    .desktop{
      display: none !important;
    }
    .mobile{
      display: flex !important;
    }
  }
</style>