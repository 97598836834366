<template>
    <div class="product_content">
        <Navbar/>
            <div class="edit_product_button_container">
                <div @click="validateForm()" class="edit_product_button">Create Product</div>
            <el-divider></el-divider>
            </div>
        <div class="edit_product_info_section">
            <div class="edit_product_images_column">
                <div class="edit_product_thumbnail_conatiner">
                    <div v-if="newProductInfo.thumbnail" class="edit_fab"><input @change="handleNewThumbnail" class="edit_product_thumbnail_file_input" type="file"  name="img" accept="image/*"><ion-icon class="menu_item" name="create-outline"></ion-icon></div>
                    <img v-if="thumbnailAdded" :src="newProductInfo.thumbnail" alt="" class="product_page_thumbnail">
                    <div v-else class="edit_product_no_thumbnail_image">
                    <input @change="handleNewThumbnail" class="edit_product_thumbnail_file_input" type="file"  name="img" accept="image/*">
                    <ion-icon style="font-size: 48px; text-align: center;" class="menu_item" name="add-outline"></ion-icon>
                    <p>Add Image</p>
                    </div>
                </div>
                <div class="edit_product_images_container">
                    <div v-for="image in newProductInfo.images" :key="image" class="edit_product_image_container">
                        <div @click="removeImage(image)" class="delete_fab">X</div>
                        <img :src="image" alt="" class="edit_product_page_image">
                    </div>
                    <div v-if="newProductInfo.images.length < 4" class="edit_product_image_container" style="background: #222; display: flex; justify-content: center; align-items: center; flex-direction: column; min-height: 80px;">
                        <input @change="handleAddImage" class="edit_product_thumbnail_file_input" type="file"  name="img" accept="image/*">
                        <ion-icon style="font-size: 28px; text-align: center;" class="menu_item" name="add-outline"></ion-icon>
                        <p style="color: #fff; text-align:center; font-size: 12px;">Add Image</p>
                    </div>
                </div>
            </div>
            <div class="product_form_column">
                <div class="product_form_left">
                    <label>Product Name</label>
                    <el-input v-model="newProductInfo.name"></el-input>
                    <!-- <label>MSRP</label>
                    <el-input type="number" v-model="newProductInfo.price"></el-input> -->
                    <label>Description</label>
                    <el-input v-model="newProductInfo.description" type="textarea"></el-input>
                    <label>Shipping and Policy</label>
                    <el-input v-model="newProductInfo.policies" type="textarea"></el-input>
                    <div class="edit_product_category_container">
                        <p>Categories</p>
                        <el-tag @click="addCategory(category)" class="categoryTag" v-for="category in categories" :key="category.name" :color="newProductInfo.categories.includes(category.name) ? '#212B6A' : '#fff'" :hit="false" :style="newProductInfo.categories.includes(category.name) ? 'color: #fff !important; margin: 5px' : 'color: #000; margin: 5px'">{{category.name}}</el-tag>
                    </div>
                </div>
                <div class="product_form_right">
                    <div class="product_switch_item">
                        <el-switch
                        v-model="newProductInfo.featured"
                        active-color="#2ecc71"
                        inactive-color="#e74c3c"
                        style="margin-left: 12px;"
                        >
                        </el-switch>
                        <p style="margin-left: 8px; font-weight:200;">Featured Product</p>
                    </div>
                    <div class="product_switch_item">
                        <el-switch
                        v-model="newProductInfo.active"
                        active-color="#2ecc71"
                        inactive-color="#e74c3c"
                        style="margin-left: 12px;"
                        >
                        </el-switch>
                        <p style="margin-left: 8px; font-weight:200;">Active Product</p>
                    </div>
                    <label>Variation Name</label>
                    <el-input v-model="newVariationName"></el-input>
                    <div class="edit_product_tag_input_container">
                        <VueTagsInput
                        placeholder="Add Variation"
                        v-model="tag"
                        :tags="tags"
                        @tags-changed="newTags => tags = newTags"
                        style="border-radius: 4px;"
                        />
                    </div>
                    <div @click="addVariation()" class="edit_product_variation_button">Add Variation</div>
                    <el-card v-for="(variation, i) in newProductInfo.variations" :key="variation.title" style="background: #fff; color:#000; border-radius: 4px; width: 90%; margin-top: 13px;" shadow="always">
                        <div slot="header" class="clearfix">
                            <span class="about_body_text" style="margin: 0; font-weight: 400;">{{variation.name}}</span>
                            <el-button @click="removeVariation(i)" style="float: right; padding: 3px 0; color: red;" type="text">X</el-button>
                        </div>
                        <el-tag v-for="v in variation.variations" :key="v" color="#fff" :hit="false" style="margin-right: 8px;">{{v}}</el-tag>
                    </el-card>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Navbar from '../../../components/Seller/Navbar'
import VueTagsInput from '@johmun/vue-tags-input';

export default {
    name: 'AddProduct',
    components:{Navbar, VueTagsInput},
    data(){
        return {
            productID: this.$route.params.id,
            product: {},
            productCompany: {},
            productCompanyProducts: [],
            tag: '',
            tags: [],
            newProductInfo:{
                thumbnail: '',
                name: '',
                price: '',
                variations: [],
                quanity: '',
                description: '',
                categories: [],
                policies: '',
                active: false,
                featured: false,
                images: []
        },
            newVariationName: '',
            thumbnailAdded: false
        }
    },
    computed: {
      user(){
        return this.$store.getters.user
      },
      company(){
        return this.$store.getters.company
      },
      categories(){
        return this.$store.getters.categories
      }
    },
    async mounted(){
        this.$store.dispatch('grabCategories')
    },
    methods:{
        routeToProduct(id){

            this.$router.push('/seller/product/' + id)
        },
        createProduct(){
            this.$store.dispatch('createProduct', this.newProductInfo)
        },
        validateForm(){
            let product = this.newProductInfo
            if(product.name === ''){
                this.$message.error('Please enter a valid product name');
                return false
            } 
            // else if(product.price === ''){
            //     this.$message.error('Please enter a valid price');
            //     return false
            // } 
            else if(product.description === ''){
                this.$message.error('Please enter a valid description');
                return false
            }else if(product.policies === ''){
                this.$message.error('Please enter a valid shipping and policy description');
                return false
            }else if(product.thumbnail === ''){
                this.$message.error('Please provide a valid thumbnail image');
                return false
            }else if(product.categories.length < 1){
                this.$message.error('Please select at least one category');
                return false
            } else {
                this.createProduct()
            }
        },
        addCategory(category){
            if(this.newProductInfo.categories.includes(category.name)){
                let cat = this.newProductInfo.categories.indexOf(category.name)
                this.newProductInfo.categories.splice(cat, 1)
            } else {
                this.newProductInfo.categories.push(category.name)
            }
        },
        addVariation(){
            let variations = []
            this.tags.forEach(tag => {
                variations.push(tag.text)
            })
            this.newProductInfo.variations.push({
                name: this.newVariationName,
                variations,
            })
            this.newVariationName = ''
            this.tags = []
        },
        removeVariation(i){
            this.newProductInfo.variations.splice(i, 1)
        },
        async handleNewThumbnail(event){
            this.newProductInfo.thumbnail = URL.createObjectURL(event.target.files[0])
            this.thumbnailAdded = true
      },
      removeImage(url){
          let removed = this.newProductInfo.images.indexOf(url)
          this.newProductInfo.images.splice(removed, 1)
      },
      async handleAddImage(event){
          this.newProductInfo.images.push(URL.createObjectURL(event.target.files[0]))
      }
    }
}
</script>

<style>
.product_content{
    min-height: 100vh;
    background: #f2f2f2;
    padding-top: 60px;
    padding-bottom: 60px;

}
.product_content h1 {
    margin-top: 15px !important;
    font-size: 35px;
}
.product_content h1, .product_content p{
    margin: 0;
}
.edit_product_info_section{
    display: flex;
    max-width: 80%;
    margin: auto;
    margin-top: 45px;
}
.edit_product_images_column{
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.product_form_column{
    width: 65%;    
    margin-left: 12px;
    padding-bottom: 12px;
}
.product_page_thumbnail{
    width: 100%;
}
.edit_product_thumbnail_conatiner{
    border-radius: 4px;
    width: 95%;
    box-shadow: .3px 0px 4px .3px #ddd;
    background: #f2f2f2;
    position: relative;
}
.edit_product_images_container{
    width: 95%;
    display: flex;
    margin-top: 12px;
    flex-wrap: wrap;
}
.edit_product_image_container{
    width: 20%;
    border-radius: 4px;
    box-shadow: .3px 0px 4px .3px #ddd;
    position: relative;
    margin-right: 5%;
}
.edit_product_page_image{
   width: 100%;
   height: 100%;
}
  .produt_container{
      display: flex;
      flex-wrap: wrap;
      justify-content:center;
      align-items: center;
  }
  .edit_product_button_container{
      max-width: 70%;
      margin: auto;
      margin-top: 23px;
      display: flex;
      justify-self: center;
      align-items: center;
      flex-direction: column;
  }
  .edit_product_button{
      background: #212B6A;
      color: #fff;
      border-radius: 4px;
      text-decoration: none;
      padding: 8px 64px;
      box-shadow: .3px 0px 4px .3px #ddd;
  }
  .edit_product_button:hover{
  background: #1b2459;
  cursor: pointer;
}
.edit_product_variation_button{
     background: #212B6A;
      color: #fff;
      border-radius: 4px;
      text-decoration: none;
      padding: 4px 64px;
      box-shadow: .3px 0px 4px .3px #ddd;
      margin: 12px 0;
}
  .edit_product_variation_button:hover{
      cursor: pointer;
        background: #1b2459;
}
.product_form_column{
    display: flex;
    background: #fff;
    border-radius: 4px;
    flex-wrap: wrap;
}
.product_form_left{
    width: 45%;
    display: flex;
    flex-direction: column;
}
.product_form_right{
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
}
.product_form_right input {
    margin: 0 !important;
    height: 40px !important;
    max-width: 90%;
    margin-left: 6% !important;
    background: #f2f2f2;
}
.product_form_right label{
    color: #000;
    font-weight: 600;
    font-size: 14px;
    margin-top: 23px;
}
.product_form_left input {
    margin: 0 !important;
    height: 40px !important;
    max-width: 90%;
    margin-left: 20px !important;
    background: #f2f2f2;
}
.product_form_left textarea{
    margin: 0 !important;
    max-width: 90%;
    margin-left: 20px !important;
    background: #f2f2f2;
}
.product_form_left label{
    color: #000;
    font-weight: 600;
    font-size: 14px;
    margin-left: 20px !important;
    margin-top: 23px;
}
.product_switch_item{
    display: flex;
    align-items: center;
    margin-top: 25px;
    background: #f2f2f2;
    width: 90%;
    height: 40px;
    border-radius: 4px;
}
.edit_product_tag_input_container{
    width: 100%;
    max-width: 90%;
    margin-top: 13px;
}
.edit_product_tag_input_container input {
    margin: 0 !important;
    height: 40px !important;
    max-width: 90%;
    background: #f2f2f2;
    padding-left: 15px !important;
}
.ti-input{
    border: none !important;
}
.ti-tag{
    background: #212B6A !important;
    border-radius: 4px !important;
}
.ti-content{
    margin-right: 12px !important;
    font-style: 'italic' !important;
    font-weight: 200;
}
.edit_product_category_container{
  width: 100%;
  max-width: 90%;
  margin-top: 16px;
  align-self: center;
}
.edit_product_category_container p {
    font-weight: bold;
}
.categoryTag:hover{
    cursor: pointer;
}
.delete_fab{
    background: #e74c3c;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    border-radius: 50%;
    margin: -12px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.delete_fab:hover{
    cursor: pointer;
    background: #c0392b;
}
.edit_fab{
background: #212B6A;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    border-radius: 50%;
    margin: -12px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.edit_fab:hover{
    cursor: pointer;
    background: #222;
}
.edit_product_no_thumbnail_image{
    background: #444;
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
}
.edit_product_no_thumbnail_image:hover{
  background: #222;
  cursor: pointer;
}
.edit_product_thumbnail_file_input{
  opacity: 0; 
  width: 100%; 
  height: 100%; 
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
}
.edit_product_thumbnail_file_input:hover{
  cursor: pointer;
}
</style>