<template>
    <div class="product_content">
        <Navbar/>
            <div v-if="product.companyId === company.id" class="edit_product_button_container">
                <router-link :to="'/seller/product/edit/'+ product.id" class="edit_product_button">Edit Product</router-link>
            <el-divider></el-divider>
            </div>
        <div class="product_info_section">
            <div class="product_images_column">
                <el-image 
                        :src="product.thumbnail" 
                        :preview-src-list="[...product.images, product.thumbnail]"
                        class="product_page_thumbnail"
                        >
                    </el-image>
                <div class="product_images_container">
                    <el-image 
                    v-for="image in product.images" :key="image" 
                        :src="image" 
                        :preview-src-list="[...product.images, product.thumbnail]"
                        class="product_page_image"
                        >
                    </el-image>
                </div>
            </div>
            <div class="product_info_column">
                <h1>{{product.name}}</h1>
                <p style="display: flex; align-items: center; margin-top: 8px;">By <span class="product_company_info_holder" @click="() => this.$router.push('/seller/company/' + product.companyId)" style="display: flex; align-items: center;"><img class="product_company_logo" v-if="product.companyId === company.id" :src="company.logo" alt="company logo"><span style="font-size: 24px; font-weight: 600; color: #212B6A; margin-left: 12px;">{{productCompany.name}}</span></span></p>
                <div v-if="user.id" class="product_feature_container">
                    <ion-icon @click="addBookmark(product.id)" v-if="!user.bookmarks.includes(product.id)" class="bookmark_icon" style="color: #222;" name="bookmark"></ion-icon>
                    <ion-icon @click="removeBookmark(product.id)" v-else class="bookmark_icon" style="color: red;" name="bookmark"></ion-icon>
                </div>
                <!-- <p class="product_price_text">${{product.price}} MSRP</p> -->
                <p class="product_description_text">{{product.description}}</p>
                <div class="product_variations_container">
                    <p style="margin-top: 8px;">Variations</p>
                    <div style="margin-top: 8px;" v-for="variation in product.variations" :key="variation.name">
                        <p style="margin-top: 8px; font-weight: 200; font-size: 15px;">{{variation.name}}</p>
                        <el-tag color="#fff" :hit="false" style="margin-right: 5px;" v-for="v in variation.variations" :key="v">{{v}}</el-tag>
                    </div>
                </div>
                <div class="product_categories_container">
                    <p style="margin-top: 12px;">Categories</p>
                        <el-tag v-for="category in product.categories" :key="category" color="#fff" :hit="false" style="margin-right: 5px;">{{category}}</el-tag>
                </div>
            </div>
        </div>
        <div class="product_all_products_section">
            <p class="heading">More Products from {{productCompany.name}}</p>
            <div class="prodcut_container">
                <carousel v-if="productCompanyProducts.length" navigationEnabled :perPage="5" :paginationEnabled="false" style="width: 98%;">
                    <slide v-for="product in productCompanyProducts" :key="product.id">
                        <Card
                        :thumbnail="product.thumbnail"
                        :name="product.name"
                        :price="product.price"
                        :routeToProduct="() => routeToProduct(product.id)"
                    />
                    </slide>
                </carousel>
                <p style="font-size:18px; font-weight: 200; margin-top: 23px;" v-else>No Products to Show!</p>
            </div>
        </div>
    </div>
</template>


<script>
import Navbar from '../../../components/Seller/Navbar'
import Card from '../../../components/Seller/Card'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
    name: 'Product',
    components:{Navbar, Card},
    data(){
        return {
            productID: this.$route.params.id,
            product: {},
            productCompany: {},
            productCompanyProducts: []
        }
    },
    computed: {
      user(){
        return this.$store.getters.user
      },
      company(){
        return this.$store.getters.company
      }
    },
    async mounted(){
        if(await firebase.auth().currentUser){
            this.$store.dispatch('getUserData', {id: 1})
        }
        await this.$store.dispatch('grabProductInfo', this.productID).then(product => {
            this.product = product
        })
        this.$store.dispatch('grabCompanyInfo', this.product.companyId).then(data => {
            this.productCompany = data.company
            this.productCompanyProducts = data.products.filter(p => p.active)
        })
    },
    async beforeRouteUpdate (to, from, next) {
    await this.$store.dispatch('grabProductInfo', to.params.id).then(product => {
        this.product = product
    })
    this.$store.dispatch('grabCompanyInfo', this.product.companyId).then(data => {
        this.productCompany = data.company
        this.productCompanyProducts = data.products.filter(p => p.active)
    })
    next();
    },
    methods:{
        routeToProduct(id){
            this.$router.replace('/seller/product/' + id)
        },
        addBookmark(id){
            this.user.bookmarks.push(id)
            this.$store.dispatch('addBookmark', id)
        },
        removeBookmark(id){
            let index = this.user.bookmarks.indexOf(id)
            this.user.bookmarks.splice(index, 1)
            this.$store.dispatch('removeBookmark', id)
            
        }

    }
}
</script>

<style>
.product_content{
    min-height: 100vh;
    background: #f2f2f2;
    padding-top: 60px;
}
.product_content h1 {
    margin-top: 15px !important;
    font-size: 35px;
}
.product_content h1, .product_content p{
    margin: 0;
}
.product_info_section{
    display: flex;
    max-width: 70%;
    margin: auto;
    margin-top: 45px;
}
.product_images_column{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.product_info_column{
    width: 60%;    
    margin-left: 12px;
}
.product_page_thumbnail{
    border-radius: 4px;
    width: 95%;
    box-shadow: .3px 0px 4px .3px #ddd;
}
.product_images_container{
    width: 95%;
    display: flex;
    margin-top: 12px;
    flex-wrap: wrap;
}
.product_page_image{
    width: 20%;
    border-radius: 4px;
    box-shadow: .3px 0px 4px .3px #ddd;
    margin-right: 5%;
}
.product_company_logo{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 0 6px;
}
.product_price_text{
    margin-top: 8px !important;
    font-size: 24px;
    font-weight: 600;
}
.product_description_text{
   margin-top: 8px !important; 
   font-style: italic;
   font-weight: 200;
}
.el-tag{
    color: #000 !important;
    border: none !important;
    min-width: 40px;
    text-align: center;
    box-shadow: .3px 0px 4px .3px #ddd;
}
.product_all_products_section{
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 70%;
    margin: auto;
    margin-top: 75px;
    padding-bottom: 34px;
}

.item_card{
    width: 225px;
}
  .time {
    font-size: 13px;
    color: #222;
  }
  
  .bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {
    width: 100%;
    display: block;
  }

  .clearfix:before,
  .clearfix:after {
      display: table;
      content: "";
  }
  
  .clearfix:after {
      clear: both
  }
  .produt_container{
      display: flex;
      flex-wrap: wrap;
      justify-content:center;
      align-items: center;
  }
  .edit_product_button_container{
      max-width: 70%;
      margin: auto;
      margin-top: 23px;
      display: flex;
      justify-self: center;
      align-items: center;
      flex-direction: column;
  }
  .edit_product_button{
      background: #222;
      color: #fff;
      border-radius: 4px;
      text-decoration: none;
      padding: 8px 64px;
  }
  .product_feature_container .bookmark_icon{
      margin: 0;
      font-size: 27px;
  }
  .bookmark_icon:hover{
      cursor: pointer;
  }
  .product_company_info_holder:hover{
      cursor: pointer;
  }
</style>