<template>
<div class="tab_content">
    <p class="heading">Featured Products</p>
    <div class="produt_container">
        <carousel v-if="featuredProducts().length" navigationEnabled :perPage="5" :paginationEnabled="false" style="width: 98%;">
            <slide v-for="product in featuredProducts()" :key="product.id">
                <Card
                    :thumbnail="product.thumbnail"
                    :name="product.name"
                    :price="product.price"
                    :routeToProduct="() => routeToProduct(product.id)"
                />
            </slide>
        </carousel>
        <p style="font-size:23px; font-weight: 400;" v-else>No Products to Show!</p>
    </div>
    <p class="heading">All Products</p>
    <div class="produt_container">
        <carousel v-if="activeProducts().length" navigationEnabled :perPage="5" :paginationEnabled="false" style="width: 98%;">
            <slide v-for="product in activeProducts()" :key="product.id">
                <Card
                    :thumbnail="product.thumbnail"
                    :name="product.name"
                    :price="product.price"
                    :routeToProduct="() => routeToProduct(product.id)"
                />
            </slide>
        </carousel>
        <p style="font-size:23px; font-weight: 400;" v-else>No Products to Show!</p>
    </div>
</div>
    
</template>


<script>
import Card from '../../Card'

export default {
    name: 'HomeTab',
    props: ['products'],
    components: { Card },
    data(){
        return {
        }
    },
    computed: {
  },
  methods:{
     routeToProduct(id){
            this.$router.push('/seller/product/' + id)
        },
        activeProducts(){
            return this.products.filter((product) => product.active)
        },
        featuredProducts(){
            return this.products.filter(
        (product) => product.active && product.featured
      )
        }
  }
}
</script>


<style scoped>
.heading{
    font-size: 28px;
    font-weight: bold;
    
}
.item_card{
    width: 225px;
}
  .time {
    font-size: 13px;
    color: #ddd;
  }
  
  .bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {
    width: 100%;
    display: block;
  }

  .clearfix:before,
  .clearfix:after {
      display: table;
      content: "";
  }
  
  .clearfix:after {
      clear: both
  }
  .produt_container{
      display: flex;
      flex-wrap: wrap;
      justify-content:center;
      align-items: center;
  }
</style>