import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import axios from 'axios'
import { Message } from 'element-ui'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    company: {},
    products: [],
    billingInfo: {},
    loading: false,
    error: '',
    categories: [],
    events: [],
    bookmarks: [],
    collections: [],
    savedStores: [],
    conversations: [],
    allProducts: [],
    allCompanies: [],
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload
    },
    setCompany(state, payload) {
      state.company = payload
    },
    setProducts(state, payload) {
      state.products = payload
    },
    setError(state, payload) {
      state.error = payload
    },
    setLoading(state, payload) {
      state.loading = payload
    },
    setBillingInfo(state, payload) {
      state.billingInfo = payload
    },
    setCategories(state, payload) {
      state.categories = payload
    },
    setEvents(state, payload) {
      state.events = payload
    },
    setBookmarks(state, payload) {
      state.bookmarks = payload
    },
    setSavedStores(state, payload) {
      state.savedStores = payload
    },
    setCollections(state, payload) {
      state.collections = payload
    },
    setConversations(state, payload) {
      state.conversations = payload
    },
    setAllProducts(state, payload) {
      state.allProducts = payload
    },
    setAllCompanies(state, payload) {
      state.allCompanies = payload
    },
  },
  actions: {
    goToRoute({ getters }) {
      router.push('/' + getters.userType)
    },
    initialUserData({ commit, dispatch }) {
      firebase
        .firestore()
        .collection('users')
        .doc(firebase.auth().currentUser.uid)
        .get()
        .then((data) => {
          commit('setUser', data.data())
          firebase
            .firestore()
            .collection('companies')
            .doc(data.data().companies[0])
            .get()
            .then((data) => {
              let products = []
              commit('setCompany', data.data())
              data.data().products.forEach((id) => {
                firebase
                  .firestore()
                  .collection('products')
                  .doc(id)
                  .get()
                  .then((data) => {
                    products.push(data.data())
                    commit('setProducts', products)
                  })
              })
            })
            .then(() => {
              commit('setLoading', false)
              dispatch('goToRoute')
            })
        })
        .then(() => {
          dispatch('getRevenueCatInfo')
          dispatch('grabCollectionData')
        })
        .catch((err) => console.log(err))
    },
    getUserData({ commit, dispatch }) {
      firebase
        .firestore()
        .collection('users')
        .doc(firebase.auth().currentUser.uid)
        .get()
        .then((data) => {
          commit('setUser', data.data())
          firebase
            .firestore()
            .collection('companies')
            .doc(data.data().companies[0])
            .get()
            .then((data) => {
              let products = []
              commit('setCompany', data.data())
              data.data().products.forEach((id) => {
                firebase
                  .firestore()
                  .collection('products')
                  .doc(id)
                  .get()
                  .then((data) => {
                    products.push(data.data())
                    commit('setProducts', products)
                  })
              })
            })
            .then(() => {
              commit('setLoading', false)
            })
        })
        .then(() => {
          dispatch('getRevenueCatInfo')
        })
        .catch((err) => console.log(err))
    },
    loginUser({ commit }, { email, password }) {
      commit('setLoading', true)
      commit('setError', '')
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          return firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((data) => {
              firebase
                .firestore()
                .collection('users')
                .doc(data.user.uid)
                .get()
                .then((data) => {
                  commit('setUser', data.data())
                  firebase
                    .firestore()
                    .collection('companies')
                    .doc(data.data().compaines[0])
                    .get()
                    .then((data) => {
                      commit('setCompany', data.data())
                    })
                    .then(() => {
                      commit('setLoading', false)
                      router.push('/')
                    })
                    .catch((err) => console.log(err))
                })
            })
            .catch((error) => {
              commit('setError', error.message)
              commit('setLoading', false)
            })
        })
        .catch(function(error) {
          commit('setError', error.message)
        })
    },
    logoutUser({ commit }) {
      firebase
        .auth()
        .signOut()
        .then(() => {
          commit('setUser', null)
          commit('setCompany', null)
        })
    },
    async getRevenueCatInfo({ commit, getters }) {
      const res = await axios.get(
        'https://api.revenuecat.com/v1/subscribers/' + getters.user.id,
        {
          headers: {
            'X-Platform': 'ios',
            'Content-Type': 'application/json',
            Authorization: 'rjIKKfRLPGsHtWrNMNEoEGpiKqfGMufR',
          },
        }
      )
      commit('setBillingInfo', res.data.subscriber)
    },
    updateUserEmail({ dispatch, getters }, newEmail) {
      firebase
        .auth()
        .currentUser.updateEmail(newEmail)
        .then(() => {
          Message({
            type: 'success',
            message: 'Changed Email to: ' + newEmail,
          })
          firebase
            .firestore()
            .collection('users')
            .doc(getters.user.id)
            .update({ email: newEmail })
          dispatch('getUserData', { id: firebase.auth().currentUser.uid })
        })
        .catch((error) => {
          Message({
            type: 'error',
            message: error.message,
          })
        })
    },
    updateUserPassword({ getters }, newPassword) {
      firebase
        .auth()
        .currentUser.updatePassword(newPassword)
        .then(() => {
          Message({
            type: 'success',
            message: 'Password has been updated!',
          })
        })
        .catch((error) => {
          console.log(getters.user.id, error.message)
          Message({
            type: 'error',
            message: error.message,
          })
        })
    },
    sendRequestFeature({ getters }, request) {
      firebase
        .firestore()
        .collection('requests')
        .add({
          request,
          sender: getters.user.id,
          dateCreated: firebase.firestore.Timestamp.now(),
        })
        .then(() => {
          Message({
            type: 'success',
            message: 'Thank you for the feedback!',
          })
        })
        .catch((err) => {
          Message({
            type: 'error',
            message: err.message,
          })
        })
    },
    async updateUserProfileImage({ commit, state }, imageURL) {
      let blob = await fetch(imageURL).then((r) => r.blob())
      let userID = firebase.auth().currentUser.uid
      firebase
        .storage()
        .ref('/user/' + userID)
        .put(blob, { contentType: 'image/jpeg' })
        .then((uploadedFile) => {
          uploadedFile.ref.getDownloadURL().then((url) => {
            firebase
              .firestore()
              .collection('users')
              .doc(userID)
              .update({ profileImage: url })
              .then(() => {
                Message({
                  type: 'success',
                  message: 'Profile Image Updated!',
                })
                commit('setUser', {
                  ...state.user,
                  profileImage: url,
                })
              })
          })
        })
        .catch((error) => {
          console.log('Error:' + error)
          Message({
            type: 'error',
            message: error.message,
          })
        })
    },
    updateUserProfile({ commit, state }, userInfo) {
      let userID = firebase.auth().currentUser.uid
      firebase
        .firestore()
        .collection('users')
        .doc(userID)
        .set(
          {
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            company: userInfo.newCompany,
            phone: userInfo.phone,
            location: {
              city: userInfo.city,
              state: userInfo.state,
              zip: userInfo.zip,
            },
          },
          { merge: true }
        )
        .then(() => {
          Message({
            type: 'success',
            message: 'Profile has been updated!',
          })
          router.push('/seller/profile')
          commit('setUser', {
            ...state.user,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            company: userInfo.newCompany,
            phone: userInfo.phone,
            location: {
              city: userInfo.city,
              state: userInfo.state,
              zip: userInfo.zip,
            },
          })
        })
        .catch((err) => {
          Message({
            type: 'error',
            message: err.message,
          })
        })
    },
    grabProductInfo({ commit }, id) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection('products')
          .doc(id)
          .get()
          .then((data) => {
            resolve(data.data())
            commit('setLoading', false)
          })
          .catch((err) => {
            reject(err.message)
            commit('setLoading', false)
          })
      })
    },
    grabCompanyInfo({ commit }, id) {
      commit('setLoading', true)

      return new Promise((resolve, reject) => {
        let company = {}
        let products = []
        firebase
          .firestore()
          .collection('companies')
          .doc(id)
          .get()
          .then(async (data) => {
            company = data.data()
            await data.data().products.forEach(async (productID) => {
              await firebase
                .firestore()
                .collection('products')
                .doc(productID)
                .get()
                .then((data) => {
                  products.push(data.data())
                })
            })
          })
          .then(() => {
            setTimeout(function() {
              commit('setLoading', false)

              resolve({ company, products })
            }, 1000)
          })
          .catch((err) => {
            reject(err.message)
            commit('setLoading', false)
          })
      })
    },
    updateProduct({ commit }, product) {
      commit('setLoading', true)
      firebase
        .firestore()
        .collection('products')
        .doc(product.id)
        .update({
          name: product.name,
          price: product.price,
          images: product.images,
          variations: product.variations,
          quanity: product.quanity,
          description: product.description,
          categories: product.categories,
          policies: product.policies,
          active: product.active,
          featured: product.featured,
        })
        .then(() => {
          Message({
            type: 'success',
            message: 'Item Updated!',
          })
          commit('setLoading', false)
          router.push('/seller/product/' + product.id)
        })
        .catch((err) => {
          commit('setLoading', false)
          Message({
            type: 'error',
            message: err.message,
          })
        })
    },
    grabCategories({ commit }) {
      firebase
        .firestore()
        .collection('assets')
        .doc('categories')
        .get()
        .then((data) => {
          commit('setCategories', data.data().all)
        })
    },
    grabEvents({ commit }) {
      firebase
        .firestore()
        .collection('assets')
        .doc('events')
        .get()
        .then((data) => {
          commit('setEvents', data.data().tradeshows)
        })
    },
    updateProductThumbnail({ commit }, productInfo) {
      commit('setLoading', true)
      firebase
        .storage()
        .ref('/product/' + productInfo.productID + '/thumbnail')
        .put(productInfo.url, {
          contentType: 'image/jpeg',
        })
        .then((uploadedFile) => {
          uploadedFile.ref.getDownloadURL().then((url) => {
            firebase
              .firestore()
              .collection('products')
              .doc(productInfo.productID)
              .update({
                thumbnail: url,
              })
              .then(() => {
                commit('setLoading', false)
                Message({
                  type: 'success',
                  message: 'Product Thumbnail Updated!',
                })
              })
              .catch((err) => {
                console.log(err.message)
                Message({
                  type: 'error',
                  message: err.message,
                })
              })
          })
        })
        .catch((err) => {
          console.log(err.message)
          Message({
            type: 'error',
            message: err.message,
          })
        })
    },
    removeImageFromProduct({ commit }, productInfo) {
      commit('setLoading', true)
      firebase
        .firestore()
        .collection('products')
        .doc(productInfo.productID)
        .update({
          images: productInfo.images,
        })
        .then(() => {
          var getRef = firebase.storage().refFromURL(productInfo.url)
          getRef
            .delete()
            .then(() => {
              commit('setLoading', false)
              Message({
                type: 'success',
                message: 'Image removed from product!',
              })
            })
            .catch((err) => {
              console.log(err.message)
              Message({
                type: 'error',
                message: err.message,
              })
            })
        })
        .catch((err) => {
          console.log(err.message)
          Message({
            type: 'error',
            message: err.message,
          })
        })
    },
    addImageToProduct({ commit }, productInfo) {
      return new Promise((resolve, reject) => {
        let productImages = productInfo.images
        commit('setLoading', true)
        firebase
          .storage()
          .ref(
            '/product/' +
              productInfo.productID +
              '/image' +
              Math.random()
                .toString(36)
                .substr(2, 5)
          )
          .put(productInfo.url, {
            contentType: 'image/jpeg',
          })
          .then((uploadedFile) => {
            uploadedFile.ref.getDownloadURL().then((url) => {
              productImages.push(url)
              firebase
                .firestore()
                .collection('products')
                .doc(productInfo.productID)
                .update({
                  images: productImages,
                })
                .then(() => {
                  commit('setLoading', false)
                  Message({
                    type: 'success',
                    message: 'Image added to product!',
                  })
                  resolve(productImages)
                })
                .catch((err) => {
                  Message({
                    type: 'error',
                    message: err.message,
                  })
                  reject(err.message)
                })
            })
          })
          .catch((err) => {
            Message({
              type: 'error',
              message: err.message,
            })
            reject(err.message)
          })
      })
    },
    async createProduct({ commit, getters, dispatch }, product) {
      let newProduct = product
      commit('setLoading', true)
      firebase
        .firestore()
        .collection('products')
        .add({
          companyId: getters.company.id,
          name: newProduct.name,
          price: newProduct.price,
          variations: newProduct.variations,
          quanity: newProduct.quanity,
          description: newProduct.description,
          categories: newProduct.categories,
          policies: newProduct.policies,
          active: newProduct.active,
          featured: newProduct.featured,
          dateCreated: Date.now().toString(),
          likes: 0,
          swipes: 0,
          rating: 0,
          images: [],
          thumbnail: '',
          id: '',
        })
        .then(async (data) => {
          newProduct.id = data.id
          const thumbnail = await fetch(newProduct.thumbnail)
          const thumbnailBlob = await thumbnail.blob()
          await firebase
            .storage()
            .ref('/product/' + newProduct.id + '/thumbnail')
            .put(thumbnailBlob, {
              cacheControl: 'max-age=31536000',
              contentType: 'image/jpeg',
            })
            .then(async (uploadedFile) => {
              await uploadedFile.ref
                .getDownloadURL()
                .then(async (url) => {
                  await firebase
                    .firestore()
                    .collection('products')
                    .doc(newProduct.id)
                    .update({
                      thumbnail: url,
                    })
                })
                .then(async () => {
                  await newProduct.images.forEach(async (imageUri) => {
                    let image = await fetch(imageUri)
                    let imageBlob = await image.blob()
                    firebase
                      .storage()
                      .ref(
                        '/product/' +
                          newProduct.id +
                          '/images' +
                          Math.random()
                            .toString(36)
                            .substr(2, 5)
                      )
                      .put(imageBlob, {
                        cacheControl: 'max-age=31536000',
                        contentType: 'image/jpeg',
                      })
                      .then(async (uploadedFile) => {
                        await uploadedFile.ref.getDownloadURL().then((url) => {
                          firebase
                            .firestore()
                            .collection('products')
                            .doc(newProduct.id)
                            .update({
                              images: firebase.firestore.FieldValue.arrayUnion(
                                url
                              ),
                            })
                        })
                      })
                      .catch((err) => {
                        commit('setLoading', false)
                        Message({
                          type: 'error',
                          message: err.message,
                        })
                      })
                  })
                })
                .then(async () => {
                  await firebase
                    .firestore()
                    .collection('products')
                    .doc(newProduct.id)
                    .update({ id: newProduct.id })
                    .then(async () => {
                      await firebase
                        .firestore()
                        .collection('companies')
                        .doc(getters.company.id)
                        .update({
                          products: firebase.firestore.FieldValue.arrayUnion(
                            newProduct.id
                          ),
                        })
                    })
                })
                .catch((err) => {
                  commit('setLoading', false)
                  Message({
                    type: 'error',
                    message: err.message,
                  })
                })
            })
            .catch((err) => {
              commit('setLoading', false)
              Message({
                type: 'error',
                message: err.message,
              })
            })
        })
        .then(() => {
          console.log('DONE')
          Message({
            type: 'success',
            message: 'Item Created!',
          })
          commit('setLoading', false)
          dispatch('getUserData', getters.user.id)
          router.push('/seller/store')
        })
        .catch((err) => {
          commit('setLoading', false)
          Message({
            type: 'error',
            message: err.message,
          })
        })
    },
    updateStoreBanner({ commit, getters }, image) {
      commit('setLoading', true)
      firebase
        .storage()
        .ref('/company/' + getters.company.id + '/banner')
        .put(image, {
          contentType: 'image/jpeg',
        })
        .then((uploadedFile) => {
          uploadedFile.ref
            .getDownloadURL()
            .then((url) => {
              firebase
                .firestore()
                .collection('companies')
                .doc(getters.company.id)
                .update({
                  banner: url,
                })
                .then(() => {
                  Message({
                    type: 'success',
                    message: 'Banner image updated!',
                  })
                  commit('setLoading', true)
                })
                .catch((err) => {
                  Message({
                    type: 'error',
                    message: err.message,
                  })
                  commit('setLoading', false)
                })
            })
            .catch((err) => {
              Message({
                type: 'error',
                message: err.message,
              })
              commit('setLoading', false)
            })
        })
        .catch((err) => {
          Message({
            type: 'error',
            message: err.message,
          })
          commit('setLoading', false)
        })
    },
    updateStoreLogo({ commit, getters }, image) {
      commit('setLoading', true)
      firebase
        .storage()
        .ref('/company/' + getters.company.id + '/logo')
        .put(image, {
          contentType: 'image/jpeg',
        })
        .then((uploadedFile) => {
          uploadedFile.ref
            .getDownloadURL()
            .then((url) => {
              firebase
                .firestore()
                .collection('companies')
                .doc(getters.company.id)
                .update({
                  logo: url,
                })
                .then(() => {
                  Message({
                    type: 'success',
                    message: 'Logo image updated!',
                  })
                  commit('setLoading', true)
                })
                .catch((err) => {
                  Message({
                    type: 'error',
                    message: err.message,
                  })
                  commit('setLoading', false)
                })
            })
            .catch((err) => {
              Message({
                type: 'error',
                message: err.message,
              })
              commit('setLoading', false)
            })
        })
        .catch((err) => {
          Message({
            type: 'error',
            message: err.message,
          })
          commit('setLoading', false)
        })
    },
    updateStore({ commit, getters }, newCompanyInfo) {
      commit('setLoading', true)
      firebase
        .firestore()
        .collection('companies')
        .doc(getters.company.id)
        .update({
          name: newCompanyInfo.name,
          about: newCompanyInfo.about,
          faq: newCompanyInfo.faq,
          categories: newCompanyInfo.categories,
          location: {
            address: newCompanyInfo.location.address,
            city: newCompanyInfo.location.city,
            state: newCompanyInfo.location.state,
            zip: newCompanyInfo.location.zip,
          },
          policies: newCompanyInfo.policies,
          events: newCompanyInfo.events,
        })
        .then(() => {
          commit('setLoading', false)
          Message({
            type: 'success',
            message: 'Store Updated!',
          })
          router.push('/seller/store')
        })
        .catch((err) => {
          Message({
            type: 'error',
            message: err.message,
          })
          commit('setLoading', false)
        })
    },
    async grabBookmarkData({ state, commit }) {
      let bookmarks = []
      let savedStores = []

      await state.user.bookmarks.forEach((productID) => {
        firebase
          .firestore()
          .collection('products')
          .doc(productID)
          .get()
          .then((data) => {
            bookmarks.push(data.data())
            commit('setBookmarks', bookmarks)
          })
      })
      await state.user.savedStores.forEach((companyID) => {
        firebase
          .firestore()
          .collection('companies')
          .doc(companyID)
          .get()
          .then((data) => {
            savedStores.push(data.data())
            commit('setSavedStores', savedStores)
          })
      })
    },
    grabStoreData({ commit }, id) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        let company = {}
        let products = []
        firebase
          .firestore()
          .collection('companies')
          .doc(id)
          .get()
          .then(async (data) => {
            company = data.data()
            await data.data().products.forEach(async (id) => {
              await firebase
                .firestore()
                .collection('products')
                .doc(id)
                .get()
                .then((data) => {
                  products.push(data.data())
                })
            })
          })
          .then(() => {
            resolve({ company, products })
            commit('setLoading', false)
          })
          .catch((err) => {
            reject(err.message)
            commit('setLoading', false)
          })
      })
    },
    addBookmark({ commit, state }, id) {
      commit('setLoading', true)
      firebase
        .firestore()
        .collection('users')
        .doc(state.user.id)
        .update({
          bookmarks: firebase.firestore.FieldValue.arrayUnion(id),
        })
        .then(() => {
          Message({
            type: 'success',
            message: 'Bookmark Added!',
          })
          commit('setLoading', false)
        })
        .catch((err) => {
          Message({
            type: 'error',
            message: err.message,
          })
          console.log(err.message)
        })
    },
    removeBookmark({ commit, state }, id) {
      commit('setLoading', true)
      firebase
        .firestore()
        .collection('users')
        .doc(state.user.id)
        .update({
          bookmarks: firebase.firestore.FieldValue.arrayRemove(id),
        })
        .then(() => {
          Message({
            type: 'success',
            message: 'Bookmark Removed!',
          })
          commit('setLoading', false)
        })
        .catch((err) => {
          Message({
            type: 'error',
            message: 'Bookmark Removed!',
          })
          console.log(err.message)
        })
    },
    addSavedStore({ commit, state }, id) {
      commit('setLoading', true)
      firebase
        .firestore()
        .collection('users')
        .doc(state.user.id)
        .update({
          savedStores: firebase.firestore.FieldValue.arrayUnion(id),
        })
        .then(() => {
          Message({
            type: 'success',
            message: 'Store Added!',
          })
          commit('setLoading', false)
        })
        .catch((err) => {
          Message({
            type: 'error',
            message: err.message,
          })
          console.log(err.message)
        })
    },
    removeSavedStore({ commit, state }, id) {
      commit('setLoading', true)
      firebase
        .firestore()
        .collection('users')
        .doc(state.user.id)
        .update({
          savedStores: firebase.firestore.FieldValue.arrayRemove(id),
        })
        .then(() => {
          Message({
            type: 'success',
            message: 'Store Removed!',
          })
          commit('setLoading', false)
        })
        .catch((err) => {
          Message({
            type: 'error',
            message: err.message,
          })
          console.log(err.message)
        })
    },
    grabCollectionData({ commit, state }) {
      commit('setLoading', true)
      let collections = []
      state.user.collections.forEach(async (collection) => {
        let newCollection = {
          id: collection.id,
          name: collection.name,
          products: [],
        }
        if (collection.products.length > 0) {
          await collection.products.forEach(async (id) => {
            await firebase
              .firestore()
              .collection('products')
              .doc(id)
              .get()
              .then((data) => {
                newCollection.products.push(data.data())
              })
          })
        }
        collections.push(newCollection)
      })
      commit('setCollections', collections)
      commit('setLoading', false)
    },
    createNewCollection({ commit, dispatch, state }, name) {
      commit('setLoading', true)
      firebase
        .firestore()
        .collection('users')
        .doc(state.user.id)
        .update({
          collections: firebase.firestore.FieldValue.arrayUnion({
            id: Math.floor((1 + Math.random()) * 0x10000)
              .toString(16)
              .substring(1),
            name,
            products: [],
          }),
        })
        .then(() => {
          Message({
            type: 'success',
            message: 'Q Added!',
          })
          dispatch('grabCollectionData')
        })
        .catch((err) => {
          Message({
            type: 'error',
            message: err.message,
          })
        })
    },
    addProductsToCollection({ commit, dispatch, state }, data) {
      commit('setLoading', true)
      let collectionsCopy = state.user.collections
      let collectionIndex = collectionsCopy.findIndex(
        (obj) => obj.id == data.id
      )
      let newCollection = collectionsCopy[collectionIndex]
      data.products.forEach((p) => {
        newCollection.products.push(p)
      })
      collectionsCopy.splice(collectionIndex, 1)
      collectionsCopy.push(newCollection)
      firebase
        .firestore()
        .collection('users')
        .doc(state.user.id)
        .update({
          collections: collectionsCopy,
        })
        .then(() => {
          commit('setLoading', false)
          Message({
            type: 'success',
            message: 'Products Added to Q!',
          })
          dispatch('grabCollectionData')
        })
        .catch((err) => {
          Message({
            type: 'error',
            message: err.message,
          })
          console.log(err.message)
        })
    },
    removeProductFromCollection({ commit, dispatch, state }, data) {
      commit('setLoading', true)
      let collectionsCopy = state.user.collections
      let collectionIndex = collectionsCopy.findIndex(
        (obj) => obj.id == data.id
      )
      let newCollection = collectionsCopy[collectionIndex]
      let productIndex = newCollection.products.indexOf(data.productID)
      newCollection.products.splice(productIndex, 1)
      collectionsCopy.splice(collectionIndex, 1)
      collectionsCopy.push(newCollection)
      firebase
        .firestore()
        .collection('users')
        .doc(state.user.id)
        .update({
          collections: collectionsCopy,
        })
        .then(() => {
          commit('setLoading', false)
          Message({
            type: 'success',
            message: 'Product Removed from Q!',
          })
          dispatch('grabCollectionData')
        })
        .catch((err) => {
          Message({
            type: 'error',
            message: err.message,
          })
          console.log(err.message)
        })
    },
    grabMessagingData({ commit, state }) {
      commit('setLoading', true)
      let conversations = []
      state.company.messages.forEach((id) => {
        firebase
          .firestore()
          .collection('conversations')
          .doc(id)
          .onSnapshot((data) => {
            conversations.push(data.data())
            commit('setConversations', conversations)
          })
      })
      commit('setLoading', false)
    },
    sendNotification({ commit, state }, info) {
      commit('setLoading', true)
      firebase
        .firestore()
        .collection('users')
        .doc(info.senderID)
        .get()
        .then((data) => {
          let token = data.data().fcmToken
          let sender = state.company.name
          let message = info.message
          firebase
            .functions()
            .httpsCallable('sendUserMessageNotifications')({
              token,
              sender,
              message,
            })
            .then((response) => {
              console.log(response.data.message)
              commit('setLoading', false)
            })
            .catch((err) => {
              commit('setLoading', false)
              console.log(err.message)
            })
        })
    },
    grabAllProducts({ commit }) {
      let products = []
      firebase
        .firestore()
        .collection('products')
        .get()
        .then((data) => {
          data.forEach((product) => {
            if (product.data().active) {
              products.push(product.data())
            }
          })
        })
        .then(() => {
          commit('setAllProducts', products)
        })
    },
    grabAllCompanies({ commit }) {
      let companies = []
      firebase
        .firestore()
        .collection('companies')
        .get()
        .then((data) => {
          data.forEach((company) => {
            if (!company.data().removed) {
              companies.push(company.data())
            }
          })
        })
        .then(() => {
          commit('setAllCompanies', companies)
        })
    },
  },
  modules: {},
  getters: {
    user(state) {
      return state.user
    },
    userType(state) {
      return state.user.type
    },
    company(state) {
      return state.company
    },
    products(state) {
      return state.products
    },
    notifications(state) {
      return state.user.notifications.alerts
    },
    billingInfo(state) {
      return state.billingInfo
    },
    activeProducts(state) {
      return state.products.filter((product) => product.active)
    },
    inactiveProducts(state) {
      return state.products.filter((product) => !product.active)
    },
    featuredProducts(state) {
      return state.products.filter(
        (product) => product.active && product.featured
      )
    },
    loading(state) {
      return state.loading
    },
    error(state) {
      return state.error
    },
    categories(state) {
      return state.categories
    },
    events(state) {
      return state.events.sort(function(a, b) {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
    },
    bookmarks(state) {
      return state.bookmarks
    },
    collections(state) {
      return state.collections.sort(function(a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1
        }
        return 0
      })
    },
    savedStores(state) {
      return state.savedStores
    },
    conversations(state) {
      return state.conversations
    },
    allProducts(state) {
      return state.allProducts
    },
    allCompanies(state) {
      return state.allCompanies
    },
  },
})
