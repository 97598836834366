<template>
<div class="settingsTabContent">
    <p class="heading">Account</p>
    <p class="settings_user_email">{{user.email}}</p>
    <div class="settings_button_container">
        <div @click="handleUpdateEmail()" class="settings_top_button">Update Email</div>
        <div @click="handleUpdatePassword()" class="settings_top_button">Update Password</div>
    </div>
    <el-divider></el-divider>
    <p class="heading">Billing Info</p>
    <p class="sub_heading">Account Number</p>
    <p class="settings_info_text">{{user.id}}</p>
    <p class="sub_heading">Current Subscription</p>
    <p class="settings_info_text">{{user.promoUser ? 'Lifetime Promo User' : 'Quarterly Subscription'}}<span style="margin-left: 22px; font-size: 14px;">{{user.promoUser ? '$0' : '$124.99'}}</span></p>
    <p class="sub_heading">Subscription Description</p>
    <p class="settings_info_text">{{user.promoUser ? 'LinQ free for lifetime!' : 'Full access to LinQ'}}</p>
    <p class="sub_heading">Expiration Date</p>
    <p v-if="billingInfo" class="settings_info_text">{{moment(
          Date(
            billingInfo.entitlements.quarterly_subscriber
              .expires_date
          )
        ).format('LL')}}
    </p>
</div>
</template>




<script>
import moment from 'moment';
export default {
    name: 'SettingsTab',
    computed: {
    user(){
      return this.$store.getters.user
    },
    company(){
      return this.$store.getters.company
    },
    billingInfo(){
      return this.$store.getters.billingInfo
    },
    },
    methods:{
        moment: function (date) {
        return moment(date);
        },
        handleUpdateEmail: function(){
            this.$prompt('Email', 'Please Enter New Email', {
          confirmButtonText: 'Update',
          cancelButtonText: 'Cancel',
          inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
          inputErrorMessage: 'Invalid Email'
        }).then(({ value }) => {
          this.$store.dispatch('updateUserEmail', value);        
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Action canceled'
          });       
        });
        },
         handleUpdatePassword: function(){
         this.$prompt('Password', 'Please enter new password', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          inputType: 'password'
        }).then(({ value }) => {
          this.$store.dispatch('updateUserPassword', value);  
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Action canceled'
          });       
        });
        },
    }
}
</script>


<style>
.settings_user_email{
    font-size: 19px;
    font-weight: 100;
    margin: 0;
    font-style: italic;
}
.settings_button_container{
  display: flex;
  height: 100%;
  align-items: flex-start;
  margin-top: 30px;
}
.settings_top_button{
  width: 164px;
  height: 43px;
  background: #212B6A;
  margin-right: 4px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#fff;
  font-size: 14px;
}
.settings_top_button:hover{
  cursor: pointer;
  background: #1b2459;
}
.settings_top_button_link{
  color: #fff; 
  text-decoration: none;
  font-size: 14px;
}
.sub_heading{
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 25px;
}
.settings_info_text{
    font-size: 12px;
    font-weight: 100;
    margin: 0;
    font-style: italic;
}
.el-button--primary{
  background: #212B6A !important;
  border: none !important;
}
.el-button--primary:hover{
  background: #1b2459 !important;
  border: none !important;
}
.el-button--default{
  color: #000;
  
}
.el-button--default:hover{
  background: #f2f2f2 !important;
  color: #000 !important;
}

</style>