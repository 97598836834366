<template>
  <div class="seller">
    <Navbar/>
    <div class="content">
      <div class="banner">
        <div class="top_profile_info">
          <img class="company_logo" :src="user.profileImage" alt="logo">
          <div class="info_text">
            <h1 style="color: #fff;">{{user.firstName}} {{user.lastName}}</h1>
            <p style="color: #fff; font-wieght: 600;">{{user.company}}</p>
            <p style="color: #fff;font-style: italic; margin-bottom: 6px; font-wieght: light; font-size: 12px;">{{user.location.city}}, {{user.location.state}} {{user.location.zip}}</p>
          </div>
        </div>
        <div class="top_profile_stats">
          <div @click="() => this.$router.push('/seller/store')" class="top_profile_stat"><p style="font-weight:bold;">Products</p><p style="font-size: 17px;">{{company.products.length}}</p></div>
          <div @click="() => this.$router.push('/seller/bookmarks')" class="top_profile_stat"><p style="font-weight:bold;">Bookmarks</p><p style="font-size: 17px;">{{user.bookmarks.length}}</p></div>
          <div class="top_profile_stat"><p style="font-weight:bold;">Promoted</p><p style="font-size: 17px;">0</p></div>
        </div>
      </div>
      <div class="profile_top">
        <div class="profile_info"></div>
        <div class="button_container">
          <router-link class="top_button_link" to="/seller/profile/edit"><div class="profile_top_button">Edit Profile</div></router-link>
        </div>
      </div>
      <el-tabs @tab-click="tabClicked" class="profile_custom_tabs" type="border-card">
        <el-tab-pane label="Notifications"><NotificationsTab/></el-tab-pane>
        <el-tab-pane label="Settings"><SettingsTab/></el-tab-pane>
        <el-tab-pane label="About"><AboutTab/></el-tab-pane>
        <el-tab-pane label="Help"><HelpTab/></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Navbar from '../../../components/Seller/Navbar'
import NotificationsTab from '../../../components/Seller/Profile/NotificationsTab'
import SettingsTab from '../../../components/Seller/Profile/SettingsTab'
import AboutTab from '../../../components/Seller/Profile/AboutTab'
import HelpTab from '../../../components/Seller/Profile/HelpTab'
export default {
  name: 'SellerProfile',
  data(){
    return {
      about: false
    }
  },
  components:{Navbar, NotificationsTab, SettingsTab,AboutTab,HelpTab},
  computed: {
    user(){
      return this.$store.getters.user
    },
    company(){
      return this.$store.getters.company
    }
  },
  methods:{
    tabClicked(tab){
      if(tab.label === 'Home'){
        this.about = false
      } else {
        this.about = true
      }
    }
  }
}
</script>


<style>
.seller{
  background: #f2f2f2;
  min-height: 100vh;
}
.content{
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.banner{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 1425px;
  height: 189px;
  max-width: 85%;
  margin-top: 98px;
  border-radius: 4px;
  background: rgb(2,0,36);
  background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(33,43,106,1) 70%);
}
.profile_top{
  width: 1425px;
  margin-top: 58px;
  max-width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top_profile_info{
  display: flex;
  justify-content: center;
  align-items: center;
}
.top_profile_stats{
  display: flex;
  width: 30%;
  justify-content: space-between;
}
.top_profile_stat{
  display: flex;
  flex-direction: column;
  justify-self: center;
  color: #fff;
  margin: 12px 23px;
  text-align: center;
}
.company_logo{
  width: 230px;
  max-width: 230px;
  height: 230px;
  max-height: 230px;
  border-radius: 50%;
  margin-bottom: -65px;
  margin-left: 40px;
  margin-right: 18px;
}
.profile_info{
  display: flex;
}
.profile_info h1{
  font-size: 31px;
  font-weight: 700;
}
.banner h1, .banner p {
  margin: 0;
}
.info_text{
  margin: 5px;
}
.button_container{
  display: flex;
  height: 100%;
  align-items: flex-start;
}
.profile_top_button{
  width: 164px;
  height: 43px;
  background: #fff;
  margin: 4px;
  border-radius: 4px;
  margin-top: -43px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#000;
  font-size: 14px;
    box-shadow: .5px 0px 6px .5px #ddd;
}
.profile_top_button:hover{
  cursor: pointer;
}
.top_button_link{
  color: #000; 
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
}
.profile_custom_tabs{
  width: 1192px;
  max-width: 85%;
  margin-top: 43px;
  border: none !important;
  box-shadow: none !important;
  margin-bottom: 33px;
}
.el-tabs--border-card>.el-tabs__header{
  background: #f2f2f2 !important;
  border: none;
}
.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active, .el-tabs--border-card>.el-tabs__header .el-tabs__item{
  color: #000 !important;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding-top: 5px;
  border: none;
  border-radius: 4px 8px 0 0;
  width: 200px;
  height: 52px;
  margin: 0;
}
.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active:hover, .el-tabs--border-card>.el-tabs__header .el-tabs__item:hover{
  color: #212B6A !important;
}
.el-tabs--border-card>.el-tabs__header .el-tabs__item{
  border: .5px solid #ddd !important;
}
@media only screen and (max-width: 900px) {
  .top_profile_stats{
    display: none !important;
  }
  .profile_top_button{
    margin-top: 0;
  }
}
@media only screen and (max-width: 1327px) {
  .top_profile_stats{
     width: 42% !important;
  }
}
</style>
