<template>
    <el-card class="item_card" :body-style="{ padding: '0px' }">
        <div :style="`background: url(${thumbnail}); background-size: cover; background-position: center; background-repeat: no-repeat;`" class="image_holder"></div>
        <div style="padding: 14px; height: 85px">
            <span>{{name}}</span>
            <div class="bottom clearfix">
                <!-- <time class="time">${{price}} MSRP</time> -->
                <el-button @click="() => this.routeToProduct()" style="color: #212B6A;" type="text" class="button">View</el-button>
            </div>
        </div>
    </el-card>
</template>

<script>
export default {
    name: 'Card',
    props: ['thumbnail', 'name', 'price', 'routeToProduct'],
}
</script>

<style scoped>
.item_card{
    width: 223px;
    margin-right: 23px;
    margin-bottom: 23px;
}
.image_holder{
    height: 250px;
    width: 100%;
    background: #222;
}
</style>