
<template>
<div class="bookmark_q_tab">
    <div @click="collectionSelected ? dialogVisible = true : handleNewQ()" class="add_collection_button">
      <ion-icon class="menu_item" name="add-outline"></ion-icon>
    </div>
    <div v-if="!collectionSelected" class="bookmark_q_container">
    <div @click="grabCollectionData(collection)" v-for="collection in collections" :key="collection.id" class="collection_card" :body-style="{ padding: '0px' }">
        <p class="q_tab_collection_title">{{collection.name}}</p>
        <p class="q_tab_collection_count">{{collection.products.length}}</p>
    </div>
    </div>
    <div v-else class="collection_detail_container">
         <div @click="back()" class="q_tab_back_button"><ion-icon class="menu_item" name="arrow-back-outline"></ion-icon></div>
        <p style="margin-left: 34px; text-align: center; margin-bottom: 12px; margin-top: 0;" class="heading">{{selectedCollection.name}}</p>
        <div class="bookmark_products_container">
          <Card
            v-for="product in selectedCollection.products"
            :key="product.id"
            :thumbnail="product.thumbnail"
            :name="product.name"
            :price="product.price"
            :routeToProduct="() => routeToProduct(product.id)"
          />
      </div>
    </div>
    <el-dialog
      title="Select Items to Add"
      :visible.sync="dialogVisible">
       <el-select v-model="productsToAdd" collapse-tags multiple placeholder="Select Products to Add" style="display: block;">
        <el-option
          v-for="item in addableProducts"
          :key="item.id"
          :label="item.name"
          :value="item.id">
          <span style="margin-right: 6px;"><img style="width: 30px; height: 30px; border-radius: 4px" :src="item.thumbnail"/></span>
          <span style="color: #000; padding-bottom: 8px;">{{ item.name }}</span>
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="addProductsToCollection()">Confirm</el-button>
      </span>
    </el-dialog>
</div>
</template>


<script>
import Card from '../Card'

export default {
  name: 'QTab',
  components: { Card },
  data(){
    return {
      collectionSelected: false,
      selectedCollection: {
        products: []
      },
      dialogVisible: false,
      productsToAdd: []
    }
  },
  computed: {
    user(){
      return this.$store.getters.user
    },
    company(){
      return this.$store.getters.company
    },
    bookmarks(){
      return this.$store.getters.bookmarks
    },
    savedStores(){
      return this.$store.getters.savedStores
    },
    collections(){
      return this.$store.getters.collections
    },
    addableProducts(){
      let addableProducts = []
      let bookmarkIDs = []
      let sellectedCollectionProductsIDs = []
      this.bookmarks.forEach(p => {
        bookmarkIDs.push(p.id)
      })
      this.selectedCollection.products.forEach(p => {
        sellectedCollectionProductsIDs.push(p.id)
      })
      bookmarkIDs.forEach((p, i) => {
        if(sellectedCollectionProductsIDs.includes(p)){
          console.log('nope')
        } else {
          addableProducts.push(this.bookmarks[i])
        }
      })
      
      return addableProducts
    }
  },
  methods:{
    routeToProduct(id){
        this.$router.push('/seller/product/' + id)
    },
    grabCollectionData(collection){
        this.selectedCollection = collection
        this.collectionSelected = true
    },
    back(){
        this.collectionSelected = false
        this.selectedCollection = {products: []}
    },
    handleNewQ(){
      this.$prompt('Please proivde a name', 'New Q', {
      confirmButtonText: 'Submit',
      cancelButtonText: 'Cancel',
    }).then(({ value }) => {
      if(value === '' || null){
        this.$message({
        type: 'error',
        message: 'Please provide a valid name'
      });       
      } else {
        this.$store.dispatch('createNewCollection', value);
      }
    }).catch(() => {
      this.$message({
        type: 'info',
        message: 'New Q canceled'
      });       
    });
    },
    handleNewItem(){
      console.log('TODO')
    },
    addProductsToCollection(){
      this.$store.dispatch('addProductsToCollection', {id: this.selectedCollection.id, products: this.productsToAdd})
      this.dialogVisible = false
      this.productsToAdd = []
      this.back()
    },
    removeProductFromCollection(product){
       this.$confirm('This will remove the item from this Q. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
            this.$store.dispatch('removeProductFromCollection', {id: this.selectedCollection.id, productID: product.id})
            let index = this.selectedCollection.products.indexOf(product)
            this.selectedCollection.products.splice(index, 1)
            //this.back()        
        }).catch((err) => {
          console.log(err)
          this.$message({
            type: 'info',
            message: 'Delete canceled'
          });          
        });
    }
  }
}
</script>

<style>
.bookmark_q_tab{
    min-height: 600px;
}
.bookmark_q_tab input {
  margin-bottom: 0 !important;
}
.bookmark_q_container{
    display: flex;
    flex-wrap: wrap;
    padding: 23px;
}
.collection_card{
    margin-right: 20px;
    margin-bottom: 23px;
    height: 280px;
    background: #212B6A;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 220px;
    border-radius: 8px;
}
.collection_card:hover{
    background: #222;
    cursor: pointer;
}
.q_tab_collection_title{
    color: #fff;
    font-weight: bold;
    text-align: center;
    max-width: 80%;
}
.q_tab_collection_count{
    color: #fff;
    font-weight: bold;
    text-align: center;
    border: 4px solid #fff;
    border-radius: 50%;
    padding: 22px 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}
.q_tab_back_button{
    background: #212B6A;
    border-radius: 5px;
    color: #fff;
    width: 60px;
    text-align: center;
    padding: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.q_tab_back_button:hover{
    cursor: pointer;
}
.add_collection_button{
  position: absolute;
  top: 0;
  right: 0;
  color: #000;
  width:  60px;
  height: 60px;
  margin: 12px;
  background: #e74c3c;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add_collection_button:hover{
  cursor: pointer;
  background: #c0392b;
}
</style>