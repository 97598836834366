
<template>
    <div class="bookmarks_seller_tab">
        <div v-if="savedStores.length">
          <div @click="routeToProduct(store.id)" v-for="store in savedStores" :key="store.id" class="bookmarks_seller_card">
            <div style="display: flex">
                <img :src="store.logo"/>
                <p>{{store.name}}</p>
            </div>
            <ion-icon class="menu_item" name="arrow-forward-outline"></ion-icon>
          </div>
        </div>
        <p v-else style="text-align: center; font-weight: 500; margin-top: 84px;">No Sellers to show!</p>
    </div>
</template>


<script>
export default {
  name: 'SellersTab',
  data(){
    return {
    }
  },
  computed: {
    user(){
      return this.$store.getters.user
    },
    company(){
      return this.$store.getters.company
    },
    savedStores(){
      return this.$store.getters.savedStores
    },
  },
  methods:{
    routeToProduct(id){
        this.$router.push('/seller/company/' + id)
    },
   
  }
}
</script>

<style>
.bookmarks_seller_tab{
    height: 600px;
    overflow: scroll;
}

.bookmarks_seller_card{
    background: #f2f2f2;
    padding: 12px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 18px;
    max-width: 80%;
    margin: auto;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.bookmarks_seller_card:hover{
    cursor: pointer;
    background: #222;
    color: #fff;
}

.bookmarks_seller_card img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 12px;
}

.bookmarks_seller_tab .menu_item{
    color: inherit;
}

</style>